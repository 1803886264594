import { map } from "lodash";
import SimpleSchema from "@inspire/data/schemas/SimpleSchema";
import { BoostOptions, BoursierOptions } from "@inspire/data/static/static";
export var BoostFields = {
    boostAlternanceApprentissage: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "J'ai envie de faire une formation post-bac en alternance / apprentissage"
    },
    boostPartir: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "Partir de chez mes parents me fait peur"
    },
    boostConnaisPersonne: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "Je me demande si je suis capable d'aller dans une formation o\xf9 je ne connais personne"
    },
    boostBudget: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "Je me demande si j'aurai un budget suffisant pour financer mes \xe9tudes"
    },
    isBoursierSecondaire: {
        type: String,
        allowedValues: Object.keys(BoursierOptions),
        optional: true,
        autoform: {
            options: BoursierOptions
        },
        label: "Je suis boursier"
    }
};
export var BoostQuestions = Object.keys(BoostFields).map(function(k) {
    return {
        key: k,
        label: BoostFields[k].label
    };
});
export var BoostSchema = new SimpleSchema(BoostFields);
