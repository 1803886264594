import { useEffect, useState, useRef } from 'react';

import { Box, BoxProps, Heading, Icon, Progress, Text } from '@chakra-ui/react';

import { ACTIVITE_STATUS_COMPLETED } from '@inspire/data/static/activites';
import { MotionBox } from '@inspire/ui/chakra/motion';
import { LightningIcon, SettingsIcon } from '@inspire/ui/icons';

import Confetti from 'components/general/Confetti';
import Link from 'components/general/Link';
import {
  useClasse,
  useMyProfile,
  useSetActivite,
  useTriggerAlgo,
  useUser,
} from 'lib/hooks';
import { PISTES_PATH } from 'lib/paths';
import {
  GTM_CATEGORY_QUESTIONNAIRE,
  GTM_ACTION_QUESTIONNAIRE_TERMINE,
  pushEvent,
} from 'lib/gtm';
import { isGatl, isRc } from '@inspire/data/helpers/user';

const sentences = [
  'Inversion des matrices',
  'Calibrage des vecteurs',
  'Synchronisation des lasers',
  'Allumage des réacteurs',
  'Activation des propulseurs',
  'Croisement des effluves',
];

const Cogs = (boxProps: BoxProps) => (
  <Box h="135px" w="140px" {...boxProps}>
    <MotionBox
      h="135px"
      w="140px"
      pos="absolute"
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 0, 0, 1, 1, 0, 1, 1, 0] }}
      // @ts-ignore
      transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}
    >
      <Icon
        pos="absolute"
        left="90px"
        top="5px"
        transform="rotate(20deg)"
        as={LightningIcon}
        boxSize={10}
        color="yellow.500"
      />
      <Icon
        pos="absolute"
        left="0px"
        top="55px"
        transform="rotate(-90deg)"
        as={LightningIcon}
        boxSize={6}
        color="yellow.500"
      />
    </MotionBox>
    <MotionBox
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      // @ts-ignore
      transition={{ duration: 2.5, repeat: Infinity, ease: 'linear' }}
      pos="absolute"
      left="10px"
      boxSize={20}
    >
      <Icon as={SettingsIcon} boxSize={20} color="gray.300" />
    </MotionBox>
    <MotionBox
      initial={{ rotate: 360 }}
      animate={{ rotate: 0 }}
      // @ts-ignore
      transition={{ duration: 2.5, repeat: Infinity, ease: 'linear' }}
      pos="absolute"
      left="56px"
      top="46px"
      boxSize={20}
    >
      <Icon as={SettingsIcon} boxSize={20} color="gray.300" />
    </MotionBox>
    <MotionBox
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      // @ts-ignore
      transition={{ duration: 2.5, repeat: Infinity, ease: 'linear' }}
      pos="absolute"
      boxSize={16}
      left="6px"
      top="70px"
    >
      <Icon as={SettingsIcon} boxSize={16} color="gray.300" />
    </MotionBox>
  </Box>
);

const AlgoProgress = ({
  activite,
  goNext,
  isAlgoGT = false,
  canTrack = false,
}) => {
  const [tick, setTick] = useState(0);
  const maxTicks = 4 * sentences.length;
  const isFinished = tick >= maxTicks;
  const { isLyceen } = useUser();

  const dotCount = tick % 4;
  const colorIndex = Math.floor(tick / 4);
  const sentenceIndex = Math.floor(tick / 4);
  const tickIntervalRef = useRef(null);

  const { myProfile } = useMyProfile();
  const userIsRc = isRc(myProfile);
  const userIsGatl = isGatl(myProfile);

  const { refetchMyProfile: fetchAlgoResults } = useMyProfile({
    enabled: false,
  });
  const { setActivite } = useSetActivite();
  const { classeHasModules } = useClasse();
  const { triggerAlgo, isError: isTriggerAlgoError } = useTriggerAlgo(
    classeHasModules || userIsGatl || userIsRc,
    {
      onSuccess: () => {
        if (!isAlgoGT) {
          setActivite(activite.activiteId, ACTIVITE_STATUS_COMPLETED);
        }
        fetchAlgoResults();
      },
    }
  );
  const shouldRedirectToPistes = !isTriggerAlgoError && tick >= maxTicks + 6; // Waiting for the confettis to finish

  useEffect(() => {
    if (isLyceen) {
      triggerAlgo();
    }
  }, [isLyceen]);

  useEffect(() => {
    if (shouldRedirectToPistes) {
      if (canTrack) {
        pushEvent(GTM_CATEGORY_QUESTIONNAIRE, GTM_ACTION_QUESTIONNAIRE_TERMINE);
      }
      clearInterval(tickIntervalRef.current);
      // push(PISTES_PATH);
      goNext();
    }
  }, [shouldRedirectToPistes]);

  useEffect(() => {
    tickIntervalRef.current = setInterval(() => setTick((x) => x + 1), 400);

    return () => {
      clearInterval(tickIntervalRef.current);
    };
  }, []);

  if (isTriggerAlgoError)
    return (
      <Text textAlign="center">
        Le calcul des recommandations de pistes n'a pas fonctionné.
        <br />
        Merci de réessayer plus tard !
        <br />
        <br />
        Tu peux quand même <Link href={PISTES_PATH}>
          explorer les pistes
        </Link>{' '}
        en attendant.
      </Text>
    );

  return (
    <Box
      pos="relative"
      // Uncomment these if we make this component a step instead of a full activite
      my={isAlgoGT ? 0 : -12}
      mb={12}
      zIndex={3}
      bg="white" /* To overlap the normal steps progress bar */
    >
      <Confetti fire={isFinished} />
      {!isAlgoGT && (
        <Progress
          h={4}
          //   isFinished
          //     ? 4
          //     : [4, '18px', '20px', '22px', '26px', '30px'][sentenceIndex]
          // }
          transition={'all 0.2s ease-in-out'}
          value={(100 * sentenceIndex) / sentences.length}
          hasStripe={!isFinished}
          isAnimated
          colorScheme={
            isFinished
              ? 'green'
              : ['yellow', 'yellow', 'yellow', 'orange', 'orange', 'red'][
                  colorIndex
                ]
          }
          borderRadius={999}
        />
      )}
      <Heading as="h4" size="md" textAlign="center">
        {isFinished ? (
          'Tes pistes sont prêtes !'
        ) : (
          <Box textAlign="left" mx="auto" maxW={340} pl={8}>
            {sentences[sentenceIndex]}
            {Array.from({ length: dotCount })
              .map(() => '.')
              .join('')}
          </Box>
        )}
      </Heading>
      {!isFinished && <Cogs pos="relative" mx="auto" />}
    </Box>
  );
};

export default AlgoProgress;
