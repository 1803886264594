import {
  Box,
  Text,
  List,
  ListItem,
  UnorderedList,
  Icon,
  Heading,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { TextareaControl } from 'formik-chakra-ui';

import { CheckIcon } from '@inspire/ui/icons';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';

import ConsigneTag from 'components/activites/ConsigneTag';
import ProfTips from 'components/activites/ProfTips';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import ClickableImage from 'components/general/ClickableImage';
import { useMyProfile, useStepContext } from 'lib/hooks';

import InfographieSrc from '../../../../public/img/infographies/projet-formation-motive.png';

const Step = ({
  question,
  formulations,
  fieldName,
  placeholder = null,
  ...boxProps
}) => {
  const { goNext } = useStepContext();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(fieldName).requireFields([
    fieldName,
  ]);

  const initialValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      <Form>
        <Box {...boxProps}>
          <TextareaControl
            label={question}
            name={fieldName}
            textareaProps={{ placeholder }}
          />
          <Text mb={1}>Exemples de formulations :</Text>
          <UnorderedList mt={1}>
            {formulations.map((f) => (
              <ListItem key={f}>{f}</ListItem>
            ))}
          </UnorderedList>
        </Box>
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

const Tick = () => (
  <Icon
    as={CheckIcon}
    boxSize={5}
    verticalAlign="middle"
    color="green.500"
    pos="relative"
    top="-2px"
    mr={0.5}
  />
);

export const FormationMotiveIntro = () => (
  <>
    <Text>
      Pour chaque voeu sur Parcoursup, il faut rédiger un projet de formation
      motivé. Il est beaucoup plus court qu’une lettre de motivation classique,
      il ne doit pas excéder 1500 caractères, il faut donc être précis et
      synthétique. Ton lecteur sera attentif aux éléments suivants :
    </Text>
    <List pl="6px !important">
      <ListItem>
        <Tick /> Ta connaissance des caractéristiques de la formation.
      </ListItem>
      <ListItem>
        <Tick /> L’adéquation entre tes compétences et les compétences attendues
        par la formation.
      </ListItem>
      <ListItem>
        <Tick /> Tes expériences scolaires/extra-scolaires en lien avec la
        formation demandée.
      </ListItem>
      <ListItem>
        <Tick /> La qualité de ta rédaction.
      </ListItem>
    </List>
    <ProfTips mt={8}>
      Pour rédiger un projet de formation motivé pertinent qui montre que
      l’élève s’est bien renseigné sur la formation visée, il est conseillé de
      suivre le module “S’informer sur les études sup’” qui propose une méthode
      de recherche d’informations sur une piste d’étude qui intéresse l’élève.
    </ProfTips>
    <Text fontStyle="italic">
      <ConsigneTag /> : Pense à une formation qui t'intéresse (pour cela tu peux
      t’inspirer des pistes d’études recommandées) et réponds aux questions,
      pour t’aider à préparer ton projet de formation motivé.
    </Text>

    <Text textAlign="center">
      Clique sur <strong>Suivant</strong> pour commencer !
    </Text>
  </>
);

export const FormationMotiveContexte = () => (
  <Step
    fieldName="formationMotiveContexte"
    question="Comment cette formation s'inscrit dans ton projet d'études ou
    professionnel ?"
    formulations={[
      'Souhaitant continuer/poursuivre dans ce domaine...',
      'Je suis très intéressé.e par...',
      'Désireux.se de participer à...',
      'Passionné.e par...',
    ]}
  />
);

export const FormationMotiveDemarches = () => (
  <Step
    fieldName="formationMotiveDemarches"
    question="Quelles démarches as-tu entrepris pour te renseigner sur la formation ? (Exemples : Prise de contact avec des professionnels ou des enseignants, participation à des journées portes ouvertes, visite du site web de l’établissement...)"
    placeholder=""
    formulations={[
      'Lors des journées portes ouvertes/salons, j’ai pu m’informer...',
      'Sur votre site, vous indiquez que...',
      "L’école [nom de l'établissement] est une référence dans le domaine de...",
    ]}
  />
);

export const FormationMotiveApports = () => (
  <Step
    fieldName="formationMotiveApports"
    question="Qu'est-ce que cette formation va t'apporter ?"
    formulations={[
      'Le programme de formation/les cours/les thèmes abordés au programme sont...',
      'Étudier dans votre université me permettrait de...',
      'Cette spécialisation est en lien avec...',
      "Les cours [de gestion] et [d'anglais] à l’université de [nom de l'établissement] m’intéressent particulièrement parce que...",
      'Je souhaite avec beaucoup d’intérêt intégrer/suivre les enseignements [de droit] que propose votre établissement...',
    ]}
  />
);

export const FormationMotiveCadre = () => (
  <Step
    fieldName="formationMotiveCadre"
    question="En quoi le cadre de la formation te correspond (rythme, encadrement, type d’établissement...) ?"
    formulations={[
      'J’apprécie de travailler en groupe car...',
      "Ce qui m’enthousiasme particulièrement dans votre formation c'est...",
      'J’ai conscience de l’importance [d’avoir le sens de l’organisation] et [d’être particulièrement autonome] pour...',
    ]}
  />
);

export const FormationMotiveAtouts = () => (
  <Step
    fieldName="formationMotiveAtouts"
    question="Quels sont tes atouts pour décrocher ce diplôme ?"
    formulations={[
      'Je suis particulièrement déterminé.e à...',
      'Rigoureux.se et travailleur.se, je...',
    ]}
  />
);

export const FormationMotiveQualites = () => (
  <Step
    fieldName="formationMotiveQualites"
    question="Quels sont tes centres d’intérêt, expériences, qualités ou compétences transversales qui te seront utiles pour la formation visée ?"
    formulations={[
      "Je pratique [une activité professionnelle/associative/sportive/artistique] depuis [4 ans] qui m'a permis de développer...",
      "Les activités que j’ai réalisées au cours de mon stage/emploi m'ont appris à...",
      'Compte tenu de ma formation/mes expériences/mes compétences, je sais...',
    ]}
  />
);

export const RecapFormationMotive = ({ profile }) => (
  <>
    <Heading size="md" textAlign="center">
      Mon projet de formation motivé
    </Heading>
    <Text>{profile.formationMotiveContexte}</Text>
    <Text>{profile.formationMotiveDemarches}</Text>
    <Text>{profile.formationMotiveApports}</Text>
    <Text>{profile.formationMotiveCadre}</Text>
    <Text>{profile.formationMotiveAtouts}</Text>
    <Text>{profile.formationMotiveQualites}</Text>
  </>
);

export const FormationMotiveSummary = () => {
  const { myProfile } = useMyProfile();

  return (
    <>
      <RecapFormationMotive profile={myProfile} />
      <Text textAlign="center">
        Si tu es satisfait.e de ton projet de formation motivé, clique sur{' '}
        <strong>Terminer</strong>. <br />
        Tu pourras également le retrouver sur la page{' '}
        <strong>Mes réponses</strong> accessible par le menu en haut à droite.
      </Text>
    </>
  );
};

export const FormationMotiveInforgraphie = () => (
  <ClickableImage
    src={InfographieSrc}
    href="/img/infographies/projet-formation-motive.png"
    alt="Règles d'or du projet de formation motivé"
  />
);
