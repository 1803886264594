import { useEffect } from 'react';
import { useUser } from 'lib/hooks';

const Hotjar = () => {
  const { user } = useUser();
  useEffect(() => {
    if (
      typeof window.hj !== 'undefined' &&
      user &&
      user.roles &&
      user.roles.length !== 0
    ) {
      window.hj('identify', user.id, {
        Rôle: user.roles[0],
      });
    }
  }, [user]);
  return null;
};

export default Hotjar;
