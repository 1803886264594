import { UseInfiniteQueryOptions } from 'react-query';

import {
  ChatThreadByIdQuery,
  MyContactRequestsQuery,
  useInfiniteArticlesQuery,
  useInfiniteChatThreadByIdQuery,
  useInfiniteEclaireursQuery,
  useInfiniteMyContactRequestsQuery,
} from 'lib/generated';

export const CONTACTS_PER_PAGE = 10;

export const useInfiniteContactRequests = (
  params?: { threadId?: string; limit?: number; skip?: number },
  options?: UseInfiniteQueryOptions<MyContactRequestsQuery>
) => {
  const { data, ...rest } = useInfiniteMyContactRequestsQuery(
    {
      limit: CONTACTS_PER_PAGE + 1,
      skip: 0,
      ...params,
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.myContactRequests?.length > CONTACTS_PER_PAGE && {
          skip: pages?.length * CONTACTS_PER_PAGE,
          // ask for an extra item to check for more pages
          limit: CONTACTS_PER_PAGE + 1,
        },
      ...options,
    }
  );
  return {
    // reduce all pages to an array,
    // removing the extra fetched item when needed
    contactRequests: data?.pages?.reduce(
      (s, n) =>
        n.myContactRequests.length > CONTACTS_PER_PAGE
          ? s.concat(n.myContactRequests).slice(0, -1)
          : s.concat(n.myContactRequests),
      []
    ),
    ...rest,
  };
};

export const MESSAGES_PER_PAGE = 20;

export const useInfiniteMessages = (
  filters?: { limit?: number; skip?: number; threadId: string },
  options?: UseInfiniteQueryOptions<ChatThreadByIdQuery>
) => {
  const { data, isLoading, ...rest } = useInfiniteChatThreadByIdQuery(
    {
      // ask for an extra item to check for more pages
      limit: MESSAGES_PER_PAGE + 1,
      skip: 0,
      ...filters,
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.messagesByThreadId?.length > MESSAGES_PER_PAGE && {
          skip: pages?.length * MESSAGES_PER_PAGE,
          // ask for an extra item to check for more pages
          limit: MESSAGES_PER_PAGE + 1,
        },
      ...options,
    }
  );
  return {
    // reduce all pages to an array,
    // removing the extra fetched item when needed
    messages: data?.pages?.reduce(
      (s, n) =>
        n.messagesByThreadId.length > MESSAGES_PER_PAGE
          ? s.concat(n.messagesByThreadId).slice(0, -1)
          : s.concat(n.messagesByThreadId),
      []
    ),
    areArticlesLoading: isLoading,
    ...rest,
  };
};

const ARTICLES_PER_PAGE = 9;

export const useInfiniteArticles = (tag?, filters?, options?) => {
  const { data, isLoading, ...rest } = useInfiniteArticlesQuery(
    {
      // ask for an extra item to check for more pages
      limit: ARTICLES_PER_PAGE + 1,
      skip: 0,
      tag,
      filters,
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.articles?.length > ARTICLES_PER_PAGE && {
          skip: pages?.length * ARTICLES_PER_PAGE,
          // ask for an extra item to check for more pages
          limit: ARTICLES_PER_PAGE + 1,
        },
      ...options,
    }
  );
  return {
    // reduce all pages to an array,
    // removing the extra fetched item when needed
    articles: data?.pages?.reduce(
      (s, n) =>
        n.articles.length > ARTICLES_PER_PAGE
          ? s.concat(n.articles).slice(0, -1)
          : s.concat(n.articles),
      []
    ),
    areArticlesLoading: isLoading,
    ...rest,
  };
};

const ECLAIREURS_PER_PAGE = 9;

export const useInfiniteEclaireurs = (filters?, options?) => {
  const { data, isLoading, ...rest } = useInfiniteEclaireursQuery(
    {
      // ask for an extra item to check for more pages
      limit: ECLAIREURS_PER_PAGE + 1,
      skip: 0,
      filters,
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.eclaireurs?.length > ECLAIREURS_PER_PAGE && {
          skip: pages?.length * ECLAIREURS_PER_PAGE,
          // ask for an extra item to check for more pages
          limit: ECLAIREURS_PER_PAGE + 1,
        },
      ...options,
    }
  );
  return {
    // reduce all pages to an array,
    // removing the extra fetched item when needed
    eclaireurs: data?.pages?.reduce(
      (s, n) =>
        n.eclaireurs.length > ECLAIREURS_PER_PAGE
          ? s.concat(n.eclaireurs).slice(0, -1)
          : s.concat(n.eclaireurs),
      []
    ),
    areEclaireursLoading: isLoading,
    ...rest,
  };
};
