import { Box } from '@chakra-ui/react';
import YouTubePlayer from 'react-player/youtube';
import FilePlayer from 'react-player/file';
import { cdnURL } from '@inspire/util/cdn';

const VideoPlayer = ({ url, containerProps = {}, ...reactPlayerProps }) => {
  const isYoutube = url.includes('youtu');

  // https://github.com/CookPete/react-player#responsive-player
  return (
    <Box pos="relative" pt="56.25%" {...containerProps}>
      {isYoutube ? (
        <YouTubePlayer
          // If using YouTube, adding ?rel=0 might keep video suggestions to the same channel
          url={url}
          controls
          width="100%"
          height="100%"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: 10,
            overflow: 'hidden',
          }}
          {...reactPlayerProps}
        />
      ) : (
        <FilePlayer
          url={cdnURL(url)}
          controls
          width="100%"
          height="100%"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: 10,
            overflow: 'hidden',
          }}
          {...reactPlayerProps}
        />
      )}
    </Box>
  );
};

export default VideoPlayer;
