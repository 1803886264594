import { Button, ButtonProps, Flex, FlexProps, HStack } from '@chakra-ui/react';

import SubmitButton from 'components/forms/SubmitButton';
import { useModules, useStepContext, useUser } from 'lib/hooks';
import Link from 'components/general/Link';
import {
  activitePath,
  activiteStepPath,
  moduleEditPath,
  modulePath,
} from 'lib/paths';
import { useRouter } from 'next/router';

export const ActiviteFooter = ({ children, ...flexProps }: FlexProps) => (
  <Flex justifyContent="flex-end" mt={[4, null, 8]} {...flexProps}>
    {children}
  </Flex>
);

export const SimpleActiviteFooter = ({
  buttonProps,
}: {
  buttonProps?: ButtonProps;
}) => {
  const { isLastStep } = useStepContext();
  const { isProf } = useUser();
  const { query } = useRouter();
  const { getModuleById } = useModules();

  const moduleId = Array.isArray(query.moduleId)
    ? query.moduleId[0]
    : query.moduleId;
  const activiteId = query.params?.[0];
  const isEnd = query.params?.[1] === 'fin';
  const step = !isEnd && Number(query.params?.[1]);
  const moduleData = getModuleById(moduleId);
  // @ts-ignore
  const activiteData: any = moduleData?.activites.find(
    (a) => a.activiteId === activiteId
  );

  const hasSteps = !!activiteData?.steps;
  const steps = activiteData?.steps;

  let backButtonPath;

  if (isEnd) {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, steps.length)
      : activitePath(moduleId, activiteId);
  } else if (step === 1 || !hasSteps) {
    backButtonPath = isProf ? moduleEditPath(moduleId) : modulePath(moduleId);
  } else {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, step - 1)
      : activitePath(moduleId, activiteId);
  }

  return (
    <ActiviteFooter>
      <HStack spacing={4}>
        {step > 1 && (
          <Link href={backButtonPath} display="inline">
            Précédent
          </Link>
        )}

        <Button {...buttonProps}>{isLastStep ? 'Terminer' : 'Suivant'}</Button>
      </HStack>
    </ActiviteFooter>
  );
};

// To use outside of a Formik context
export const NonFormikActiviteFooter = ({ buttonProps }) => {
  const { goNext, isLastStep } = useStepContext();
  const { isProf } = useUser();
  const { query } = useRouter();
  const { getModuleById } = useModules();

  const moduleId = Array.isArray(query.moduleId)
    ? query.moduleId[0]
    : query.moduleId;
  const activiteId = query.params?.[0];
  const isEnd = query.params?.[1] === 'fin';
  const step = !isEnd && Number(query.params?.[1]);
  const moduleData = getModuleById(moduleId);
  // @ts-ignore
  const activiteData: any = moduleData?.activites.find(
    (a) => a.activiteId === activiteId
  );

  const hasSteps = !!activiteData?.steps;
  const steps = activiteData?.steps;

  let backButtonPath;

  if (isEnd) {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, steps.length)
      : activitePath(moduleId, activiteId);
  } else if (step === 1 || !hasSteps) {
    backButtonPath = isProf ? moduleEditPath(moduleId) : modulePath(moduleId);
  } else {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, step - 1)
      : activitePath(moduleId, activiteId);
  }

  return (
    <ActiviteFooter>
      <HStack spacing={4}>
        {step > 1 && (
          <Link href={backButtonPath} display="inline">
            Précédent
          </Link>
        )}

        <Button
          {...buttonProps}
          isDisabled={buttonProps.isLoading || buttonProps.isDisabled}
          onClick={(e) => {
            if (isProf) {
              e.preventDefault();
              goNext();
            }
            buttonProps?.onClick?.(e);
          }}
        >
          {isLastStep ? 'Terminer' : 'Suivant'}
        </Button>
      </HStack>
    </ActiviteFooter>
  );
};

// To use in a Formik context
export const FormActiviteFooter = ({ isLoading, allowNext = true }) => {
  const { goNext, isLastStep } = useStepContext();
  const { isProf } = useUser();
  const { query } = useRouter();
  const { getModuleById } = useModules();

  const moduleId = Array.isArray(query.moduleId)
    ? query.moduleId[0]
    : query.moduleId;
  const activiteId = query.params?.[0];
  const isEnd = query.params?.[1] === 'fin';
  const step = !isEnd && Number(query.params?.[1]);
  const moduleData = getModuleById(moduleId);
  // @ts-ignore
  const activiteData: any = moduleData?.activites.find(
    (a) => a.activiteId === activiteId
  );

  const hasSteps = !!activiteData?.steps;
  const steps = activiteData?.steps;

  let backButtonPath;

  if (isEnd) {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, steps.length)
      : activitePath(moduleId, activiteId);
  } else if (step === 1 || !hasSteps) {
    backButtonPath = isProf ? moduleEditPath(moduleId) : modulePath(moduleId);
  } else {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, step - 1)
      : activitePath(moduleId, activiteId);
  }

  return (
    <ActiviteFooter>
      <HStack spacing={4}>
        {step > 1 && (
          <Link href={backButtonPath} display="inline">
            Précédent
          </Link>
        )}

        <SubmitButton
          isLoading={isLoading}
          onClick={(e) => {
            if (isProf) {
              e.preventDefault();
              goNext();
            }
          }}
          disabled={!allowNext}
        >
          {isLastStep ? 'Terminer' : 'Suivant'}
        </SubmitButton>
      </HStack>
    </ActiviteFooter>
  );
};
