import create from 'zustand';
import { persist } from 'zustand/middleware';
import { mountStoreDevtool } from 'simple-zustand-devtools';

import type { BannerOptions } from '@inspire/ui/chakra/layout/Banner';

interface BackButtonOptions {
  path: string;
  label?: string;
}

interface ProfSignupClasse {
  classeName: string;
  niveau: string;
  familleMetiers: string;
  filiere?: string;
}

interface LyceenSignupProfile {
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
}

interface ProfSignupProfile {
  // firstName: string;
  // lastName: string;
  // departement: string;
  hadInspirePresentation: boolean;
}

interface EclaireurSignupProfile {
  firstName: string;
  lastName: string;
  birthDate: Date;
  codePostal: string;
}

interface LyceenSignupClasse {
  signupToken: string;
}

interface MemoryStore {
  eclaireurSignupProfile?: EclaireurSignupProfile;
  setEclaireurSignupProfile: (profile: unknown) => void;
  profSignupProfile?: ProfSignupProfile;
  profSignupClasse?: ProfSignupClasse;
  setProfSignupProfile: (profile: unknown) => void;
  setProfSignupClasse: (classe: unknown) => void;
  clearProfSignupProfile: () => void;
  clearProfSignupClasse: () => void;
  lyceenSignupProfile?: LyceenSignupProfile;
  lyceenSignupClasse?: LyceenSignupClasse;
  setLyceenSignupProfile: (profile: unknown) => void;
  setLyceenSignupClasse: (classe: unknown) => void;
  clearLyceenSignupProfile: () => void;
  backButton?: BackButtonOptions;
  setBackButton: (options: BackButtonOptions) => void;
  clearBackButton: () => void;
  banner?: BannerOptions;
  setBanner: (options: BannerOptions) => void;
  clearBanner: () => void;
}

export const useMemoryStore = create<MemoryStore>((set) => ({
  eclaireurSignupProfile: null,
  setEclaireurSignupProfile: (profile: EclaireurSignupProfile) =>
    set({ eclaireurSignupProfile: profile }),
  profSignupProfile: null,
  profSignupClasse: null,
  setProfSignupProfile: (profile: ProfSignupProfile) =>
    set({ profSignupProfile: profile }),
  setProfSignupClasse: (classe: ProfSignupClasse) =>
    set({ profSignupClasse: classe }),
  clearProfSignupProfile: () => set({ profSignupProfile: null }),
  clearProfSignupClasse: () => set({ profSignupClasse: null }),
  lyceenSignupProfile: null,
  lyceenSignupClasse: null,
  setLyceenSignupProfile: (profile: LyceenSignupProfile) =>
    set({ lyceenSignupProfile: profile }),
  setLyceenSignupClasse: (classe: LyceenSignupClasse) =>
    set({ lyceenSignupClasse: classe }),
  clearLyceenSignupProfile: () => set({ lyceenSignupProfile: null }),
  backButtonPath: null,
  setBackButton: (options) => set({ backButton: options }),
  clearBackButton: () => set({ backButton: null }),
  banner: null,
  setBanner: (options) => set({ banner: options }),
  clearBanner: () => set({ banner: null }),
}));

interface LocalStore {
  set: (key: string, value: unknown) => void;
  setTrue: (key: string) => void;
  setFalse: (key: string) => void;
}

export const useLocalStore = create<LocalStore>(
  persist(
    (set, get) => ({
      set: (key, value) => set({ ...get(), [key]: value }),
      setTrue: (key) => set({ ...get(), [key]: true }),
      setFalse: (key) => set({ ...get(), [key]: false }),
    }),
    { name: 'inspire-zustand-local' }
  )
);

if (process.env.NODE_ENV === 'development') {
  // To not break SSR
  if (typeof document !== 'undefined') {
    mountStoreDevtool('Zustand Memory Store', useMemoryStore);
    mountStoreDevtool('Zustand Local Store', useLocalStore);
  }
}
