import { Box, Text } from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { TextareaControl } from 'formik-chakra-ui';

import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import {
  SoftSkillsOptions,
  SoftSkillsDifficultesOptions,
  SoftSkillsRessentiOptions,
} from '@inspire/data/static/soft-skills';

import { useMyProfile } from 'lib/hooks';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import SelectCheckboxActivite from 'components/activites/SelectCheckboxActivite';
import ProfTips from 'components/activites/ProfTips';
import {
  RecapCheckbox,
  RecapTextArea,
} from 'components/activites/recap-helpers';
import { SoftSkillsDefinition } from 'components/activites/seconde/decouvrir-les-soft-skills/soft-skills-et-referentiel';

export const DefinitionSoftSkills = SoftSkillsDefinition;

const TextAreaActivite = ({ goNext, fieldName, label, textareaProps }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(fieldName).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      <Form>
        <TextareaControl
          label={label}
          name={fieldName}
          textareaProps={textareaProps}
        />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const DecrisExperienceSoftSkills = (props) => (
  <TextAreaActivite
    fieldName="softSkillsExperience"
    label="Décris ton expérience de stage en une ou deux phrases."
    textareaProps={{
      placeholder:
        'Exemple : J’ai accueilli et orienté les différents clients.',
    }}
    {...props}
  />
);

export const DecrisActionsSoftSkills = (props) => (
  <TextAreaActivite
    fieldName="softSkillsActions"
    label="Décris tes actions durant cette expérience."
    textareaProps={{
      placeholder:
        'Exemples : Prise en main du logiciel, imprimer la liste des participants, envoyer les invitations, messages sur les réseaux sociaux, etc.',
    }}
    {...props}
  />
);

export const DecrisAmeliorationsSoftSkills = (props) => (
  <TextAreaActivite
    fieldName="softSkillsAmeliorations"
    label="Décris ce que tu aurais pu améliorer durant cette expérience."
    textareaProps={{
      placeholder:
        "Exemples : Inviter les clients plus tôt pour être sûr qu'ils puissent venir, préparer une liste des choses à faire pour ne rien oublier, penser à retenir le nom des clients.",
    }}
    {...props}
  />
);

export const SelectSoftSkills = (props) => (
  <SelectCheckboxActivite
    fieldName="softSkillsList"
    label="Sélectionne les soft skills que tu as développées durant cette expérience. (tu peux en sélectionner jusqu'à 3)"
    options={SoftSkillsOptions}
    {...props}
  />
);

export const SelectDifficultesSoftSkills = (props) => (
  <SelectCheckboxActivite
    fieldName="softSkillsDifficultes"
    label="Sélectionne les difficultés que tu as rencontrées durant cette expérience. (tu peux en sélectionner jusqu'à 2)"
    options={SoftSkillsDifficultesOptions}
    {...props}
  />
);

export const SelectRessentiSoftSkills = (props) => (
  <SelectCheckboxActivite
    fieldName="softSkillsRessenti"
    label="Sélectionne ce que tu as ressenti durant cette expérience."
    options={SoftSkillsRessentiOptions}
    {...props}
  />
);

export const RecapSoftSkills = ({ profile }) => (
  <Box>
    <RecapTextArea
      profile={profile}
      fieldName="softSkillsExperience"
      header="Mon experience"
    />
    <RecapCheckbox
      profile={profile}
      fieldName="softSkillsRessenti"
      header="Mon ressenti durant cette experience"
      options={SoftSkillsRessentiOptions}
    />
    <RecapTextArea
      profile={profile}
      fieldName="softSkillsActions"
      header="Mes actions durant cette experience"
    />
    <RecapCheckbox
      profile={profile}
      fieldName="softSkillsList"
      header="Les soft skills développées durant cette expérience"
      options={SoftSkillsOptions}
    />
    <RecapCheckbox
      profile={profile}
      fieldName="softSkillsDifficultes"
      header="Les difficultés rencontrées durant cette experience"
      options={SoftSkillsDifficultesOptions}
    />
    <RecapTextArea
      profile={profile}
      fieldName="softSkillsAmeliorations"
      header="Ce que j'aurais pu améliorer durant cette expérience"
    />
  </Box>
);

export const MyRecapSoftSkills = () => {
  const { myProfile } = useMyProfile();
  return (
    <>
      <RecapSoftSkills profile={myProfile} />
      <ProfTips>
        <Text>
          Le récapitulatif des informations entrées par l'élève s'affiche sur
          cette page.
        </Text>
      </ProfTips>
    </>
  );
};

export const PlanActionObjectif = (props) => (
  <>
    <Text textAlign={'center'}>
      Réfléchis à un objectif personnel à atteindre.
    </Text>
    <TextAreaActivite
      fieldName="planActionObjectif"
      label="Quel est cet objectif ?"
      textareaProps={{
        placeholder: 'Exemple : Obtenir mon TOEFL.',
      }}
      {...props}
    />
  </>
);

export const PlanActionTemps = (props) => (
  <TextAreaActivite
    fieldName="planActionTemps"
    label="Combien de temps te donnes-tu pour atteindre ton objectif ?"
    textareaProps={{
      placeholder: 'Exemple : Obtenir mon TOEFL dans 6 mois.',
    }}
    {...props}
  />
);

export const PlanActionEntreprendre = (props) => (
  <TextAreaActivite
    fieldName="planActionEntreprendre"
    label="Quelles actions complémentaires peux-tu entreprendre pour atteindre cet objectif ?"
    textareaProps={{
      placeholder:
        'Exemple : prendre des cours d’anglais intensifs, regarder des séries en anglais, écouter des podcast en anglais, télécharger une application avec des cours d’anglais.',
    }}
    {...props}
  />
);

export const RecapPlanAction = ({ profile }) => (
  <Box>
    <RecapTextArea
      profile={profile}
      fieldName="planActionObjectif"
      header="Mon objectif personnel"
    />
    <RecapTextArea
      profile={profile}
      fieldName="planActionTemps"
      header="Combien de temps je me donne pour atteindre mon objectif"
    />
    <RecapTextArea
      profile={profile}
      fieldName="planActionEntreprendre"
      header="Ce que je peux entreprendre pour atteindre cet objectif"
    />
    <RecapTextArea
      profile={profile}
      fieldName="planActionComplementaire"
      header="Mes actions complémentaires pour atteindre cet objectif"
    />
  </Box>
);

export const MyRecapPlanAction = () => {
  const { myProfile } = useMyProfile();
  return (
    <>
      <RecapPlanAction profile={myProfile} />
      <ProfTips>
        <Text>
          Le récapitulatif des informations entrées par l'élève s'affiche sur
          cette page.
        </Text>
      </ProfTips>
    </>
  );
};
