import { useEffect, ReactNode } from 'react';

import { useRouter } from 'next/router';

import {
  ROLE_LYCEEN,
  ROLE_PROF,
  ROLE_ECLAIREUR,
  ROLE_SSO_REGISTERED,
} from '@inspire/data/static/roles';
import { haveCommonItems } from '@inspire/util/lang';

import {
  useClasse,
  useIsEclaireurProfileComplete,
  useIsQuestionnaireComplete,
  useUser,
  useMyProfile,
} from 'lib/hooks';
import {
  CHARTE_ECLAIREUR_PATH,
  CONNEXION_PATH,
  ETRE_ECLAIREUR_PATH,
  LOGGED_IN_HOME_PATH,
  LOGGED_OUT_HOME_PATH,
  monCompteTabPath,
  PROFIL_ECLAIREUR_PATH,
  QUESTIONNAIRE_PATH,
  REJOINDRE_UNE_CLASSE_PATH,
  INSCRIPTION_PATH,
  VALIDATION_EMAIL_PATH,
  questionnaireStepPath,
} from 'lib/paths';

export const useRedirectIfNotAllowed = (path: string, isAllowed: boolean) => {
  const { push } = useRouter();

  useEffect(() => {
    !isAllowed && push(path);
  }, [path, isAllowed]);
};

export const useRedirectIfAllowed = (path: string, isAllowed: boolean) => {
  const { push } = useRouter();

  useEffect(() => {
    isAllowed && push(path);
  }, [path, isAllowed]);
};

export const OnlyLoggingOngoing = ({ children }) => {
  const { user } = useUser();
  const isAllowed = !user;
  const isSsoRegistered = user?.roles.includes(ROLE_SSO_REGISTERED);

  useRedirectIfNotAllowed(
    isSsoRegistered ? INSCRIPTION_PATH : LOGGED_IN_HOME_PATH,
    isAllowed
  );

  return <>{children}</>;
};

const useRedirectToEmailVerificationPageIfAllowed = (enable: boolean) => {
  const { myProfile } = useMyProfile();
  const redirectToValidationEmailPage = myProfile.isEmailVerified === false;

  useRedirectIfNotAllowed(
    VALIDATION_EMAIL_PATH,
    !(enable && myProfile && redirectToValidationEmailPage)
  );
};

export const OnlyLoggedIn = (options: {
  children: any;
  allowSsoRegistered?: boolean;
}) => {
  const { children, allowSsoRegistered } = options;
  const { user, isUserLoading } = useUser();

  const isSsoRegistered = user?.roles.includes(ROLE_SSO_REGISTERED);
  const isAllowed =
    Boolean(isUserLoading || (user && !isSsoRegistered)) ||
    allowSsoRegistered === true;

  useRedirectIfNotAllowed(
    isSsoRegistered ? INSCRIPTION_PATH : CONNEXION_PATH,
    isAllowed
  );

  // Ensure the email validation page is show for Prof and Eclaireur
  // The email validation must be free from this
  const { asPath } = useRouter();
  const enforceEmailValidation =
    !asPath.startsWith(`/verifier-email/`) &&
    (user?.roles.includes(ROLE_PROF) || user?.roles.includes(ROLE_ECLAIREUR));
  useRedirectToEmailVerificationPageIfAllowed(
    isAllowed && !isSsoRegistered && enforceEmailValidation
  );

  return <>{children}</>;
};

export const OnlyLoggedOut = ({ children }) => {
  const { user } = useUser();
  const isAllowed = !user;

  useRedirectIfNotAllowed(LOGGED_IN_HOME_PATH, isAllowed);

  return <>{children}</>;
};

export const OnlyForRoles = (options: {
  onlyWithVerifiedEmail?: boolean;
  children: ReactNode;
  roles: string[];
}) => {
  const { children, roles } = options;
  const { user, isUserLoading } = useUser();
  const isAllowed =
    isUserLoading || (roles ? haveCommonItems(user?.roles, roles) : true);

  useRedirectIfNotAllowed(LOGGED_IN_HOME_PATH, isAllowed);

  const isSsoRegistered = user?.roles.includes(ROLE_SSO_REGISTERED);

  return (
    <OnlyLoggedIn allowSsoRegistered={isSsoRegistered}>{children}</OnlyLoggedIn>
  );
};

export const ExcludeEclaireur = ({ children }) => {
  const { user, isUserLoading } = useUser();
  const isAllowed = isUserLoading || !user?.roles?.includes(ROLE_ECLAIREUR);

  useRedirectIfNotAllowed(LOGGED_IN_HOME_PATH, isAllowed);

  return <>{children}</>;
};

export const ExcludeProf = ({ children }) => {
  const { user, isUserLoading } = useUser();
  const isAllowed = isUserLoading || !user?.roles?.includes(ROLE_PROF);

  useRedirectIfNotAllowed(LOGGED_IN_HOME_PATH, isAllowed);

  return <>{children}</>;
};

export const ExcludeSeconde = ({ children }) => {
  const { classe } = useClasse();
  const isAllowed = classe && classe.niveau === 'seconde';

  useRedirectIfAllowed(LOGGED_IN_HOME_PATH, isAllowed);

  return <>{children}</>;
};

export const OnlyClassesWithoutModules = ({ children }) => {
  const { user, isUserLoading } = useUser();
  const { classeIsGatlRc, isClasseLoading } = useClasse();
  const isLoggedIn = Boolean(isUserLoading || user);
  const isAllowed = isClasseLoading || !classeIsGatlRc;

  useRedirectIfNotAllowed(
    isLoggedIn ? LOGGED_IN_HOME_PATH : LOGGED_OUT_HOME_PATH,
    isAllowed
  );

  return <ExcludeProf>{children}</ExcludeProf>;
};

export const OnlyClassesWithModules = ({ children }) => {
  const { user, isUserLoading } = useUser();
  const { classeHasModules, isClasseLoading } = useClasse();
  const isLoggedIn = Boolean(isUserLoading || user);
  const isAllowed = isClasseLoading || classeHasModules;

  useRedirectIfNotAllowed(
    isLoggedIn ? LOGGED_IN_HOME_PATH : LOGGED_OUT_HOME_PATH,
    isAllowed
  );

  return <ExcludeEclaireur>{children}</ExcludeEclaireur>;
};

export const OnlyForProf = ({ children }) => (
  <OnlyForRoles onlyWithVerifiedEmail roles={[ROLE_PROF]}>
    {children}
  </OnlyForRoles>
);

export const OnlyForLyceen = ({ children }) => (
  <OnlyForRoles roles={[ROLE_LYCEEN]}>{children}</OnlyForRoles>
);

export const OnlyForEclaireur = ({ children }) => {
  return (
    <OnlyForRoles onlyWithVerifiedEmail roles={[ROLE_ECLAIREUR]}>
      {children}
    </OnlyForRoles>
  );
};

export const OnlyForSsoRegistered = ({ children }) => (
  <OnlyForRoles roles={[ROLE_SSO_REGISTERED]}>{children}</OnlyForRoles>
);

export const OnlyWithClasse = ({ children }) => {
  const { classe, isClasseLoading } = useClasse();

  const isAllowed = isClasseLoading || classe;

  useRedirectIfNotAllowed(LOGGED_IN_HOME_PATH, isAllowed);

  return <>{children}</>;
};

export const LyceenQuestionnaireRedirect = ({ children }) => {
  const { asPath } = useRouter();
  const { classe } = useClasse();

  const isAllowed =
    useIsQuestionnaireComplete() ||
    asPath.startsWith(monCompteTabPath('')) ||
    asPath.startsWith(VALIDATION_EMAIL_PATH) ||
    asPath.startsWith(REJOINDRE_UNE_CLASSE_PATH) ||
    (classe && classe.niveau === 'seconde');

  // If the user is in a classe, they goes to the 1st question of the questionnaire
  useRedirectIfNotAllowed(
    classe ? questionnaireStepPath(1) : QUESTIONNAIRE_PATH,
    isAllowed
  );

  return <>{children}</>;
};

export const EclaireurProfileRedirect = ({ children }) => {
  const { asPath } = useRouter();
  const isAllowed =
    useIsEclaireurProfileComplete() ||
    asPath.startsWith(monCompteTabPath('')) ||
    asPath.startsWith(PROFIL_ECLAIREUR_PATH) ||
    asPath.startsWith(CHARTE_ECLAIREUR_PATH) ||
    asPath.startsWith(VALIDATION_EMAIL_PATH) ||
    asPath.startsWith(ETRE_ECLAIREUR_PATH);
  useRedirectIfNotAllowed(PROFIL_ECLAIREUR_PATH, isAllowed);
  return <>{children}</>;
};
