import SimpleSchema from "simpl-schema";
import { FrenchPhoneRegEx } from "@inspire/data/helpers/schemas";
SimpleSchema.extendOptions([
    "autoform",
    "question"
]);
var regExpMessages = [
    {
        msg: "Ce champ est invalide."
    },
    {
        exp: SimpleSchema.RegEx.Email,
        msg: "Cette adresse email n'est pas valide."
    },
    {
        exp: SimpleSchema.RegEx.WeakEmail,
        msg: "Cette adresse email n'est pas valide."
    },
    {
        exp: SimpleSchema.RegEx.Domain,
        msg: "Le domaine de cette adresse email n'est pas valide."
    },
    {
        exp: SimpleSchema.RegEx.WeakDomain,
        msg: "Le domaine de cette adresse email n'est pas valide."
    },
    {
        exp: SimpleSchema.RegEx.IP,
        msg: "Cette adresse IP n'est pas une adresse IPv4 ou IPv6."
    },
    {
        exp: SimpleSchema.RegEx.IPv4,
        msg: "Cette adresse IP n'est pas une adresse IPv4."
    },
    {
        exp: SimpleSchema.RegEx.IPv6,
        msg: "Cette adresse IP n'est pas une adresse IPv6."
    },
    {
        exp: SimpleSchema.RegEx.Url,
        msg: "Ce lien n'est pas valide."
    },
    {
        exp: SimpleSchema.RegEx.Id,
        msg: "Cette ID doit \xeatre compos\xe9e uniquement de chiffres ou de lettres."
    },
    {
        exp: FrenchPhoneRegEx,
        msg: "Le num\xe9ro doit \xeatre au format 06 XX XX XX XX ou 07 XX XX XX XX."
    }, 
];
SimpleSchema.setDefaultMessages({
    messages: {
        en: {
            required: "Ce champ est obligatoire.",
            number: "Ce champ doit \xeatre un nombre.",
            minString: "Ce champ doit avoir au moins {{min}} caract\xe8res.",
            maxString: "Ce champ ne peut pas avoir plus de {{max}} caract\xe8res.",
            minNumber: "Ce nombre doit \xeatre sup\xe9rieur \xe0 {{min}}.",
            maxNumber: "Ce nombre doit \xeatre inf\xe9rieur \xe0 {{max}}.",
            minDate: "Cette date doit \xeatre au minimum le {{min}}.",
            maxDate: "Cette date doit \xeatre au maxmumum le {{max}}.",
            badDate: "Cette date est invalide.",
            minCount: "Tu dois choisir au moins {{minCount}} option(s).",
            maxCount: "Tu ne peux pas choisir plus de {{maxCount}} options.",
            noDecimal: "Ce champ doit \xeatre un nombre entier.",
            notAllowed: "Cette valeur n'est pas autoris\xe9e.",
            expectedString: "Ce champ doit \xeatre du texte.",
            expectedNumber: "Ce champ doit \xeatre un nombre.",
            expectedBoolean: "Tu dois compl\xe9ter ce champ.",
            expectedArray: "Ce champ doit \xeatre un tableau.",
            expectedObject: "Ce champs doit \xeatre un objet.",
            expectedConstructor: "Ce champ doit \xeatre un {{type}}.",
            keyNotInSchema: "{{key}} n'est pas autoris\xe9 dans le sch\xe9ma.",
            passwordMismatch: "Les mots de passe entr\xe9s sont diff\xe9rents.",
            notUnique: "Il existe d\xe9j\xe0 un utilisateur avec cette adresse mail.",
            invalidZipCode: "Ce code postal est invalide.",
            isBelowAge: "D\xe9sol\xe9, tu dois avoir plus de 17 ans pour devenir \xe9claireur !",
            isAboveAge: "D\xe9sol\xe9, tu dois avoir moins de 28 ans pour devenir \xe9claireur !",
            badVideoUrl: "Ta vid\xe9o doit \xeatre h\xe9berg\xe9e sur YouTube (https://www.youtube.com/watch?v=...).",
            ageLimit: "L’inscription est r\xe9serv\xe9e aux visiteurs ayant au moins 15 ans. Rien ne presse, reviens lorsque tu auras souffl\xe9 plus de bougies !",
            doubleMaths: "Tu ne peux pas choisir \xe0 la fois les options Math\xe9matiques compl\xe9mentaires et Math\xe9matiques expertes.",
            engagementCount: "Tu dois choisir entre 2 et 5 engagements.",
            regEx: function regEx(param) {
                var regExp = param.regExp;
                // See if there's one where exp matches this expression
                var msgObj;
                if (regExp) {
                    msgObj = regExpMessages.find(function(o) {
                        return o.exp && o.exp.toString() === regExp;
                    });
                }
                return msgObj ? msgObj.msg : "Le format n'est pas valide";
            }
        }
    }
});
// make the keys passed as a parameter required on the schema
// if keys is empty, all fields will be required
SimpleSchema.prototype.requireFields = function(keys) {
    var fields = {};
    this._schemaKeys.forEach(function(key) {
        if (!keys || keys.length && keys.includes(key)) {
            fields[key] = {
                optional: false
            };
        }
    });
    return new SimpleSchema(this).extend(fields);
};
export default SimpleSchema;
