import DecryptIcon from '@inspire/ui/assets/undraw/decrypt.svg';
import DecorateIcon from '@inspire/ui/assets/undraw/decorate.svg';
import ArgumentIcon from '@inspire/ui/assets/undraw/argument.svg';
import TypewriterIcon from '@inspire/ui/assets/undraw/typewriter.svg';
import PersonDocumentIcon from '@inspire/ui/assets/undraw/person-document.svg';
import GoalsIcon from '@inspire/ui/assets/undraw/goals.svg';
import FileAnalysisIcon from '@inspire/ui/assets/undraw/file-analysis.svg';
import IdentityIcon from '@inspire/ui/assets/undraw/identity.svg';
import BusinessIcon from '@inspire/ui/assets/undraw/business.svg';
import PercentagesIcon from '@inspire/ui/assets/undraw/percentages.svg';
import GraduationIcon from '@inspire/ui/assets/undraw/graduation.svg';
import EducatorIcon from '@inspire/ui/assets/undraw/educator.svg';
import LaptopIcon from '@inspire/ui/assets/undraw/laptop.svg';

import {
  ACTIVITE_CONTENT_TYPE_VIDEO,
  ACTIVITE_CONTENT_TYPE_FORM,
  ACTIVITE_CONTENT_TYPE_GAME,
  ACTIVITE_CONTENT_TYPE_ORAL,
} from '@inspire/data/static/activites';
import {
  AnalyseOffre,
  LectureDuResultat,
  LettreDeMotivATrous,
  SoftSkills,
  StageEnEntreprise,
} from 'components/activites/terminale/ValoriserCompetences';
import {
  EtapesEntreprise,
  ParcoursEntrepreneurs,
  ParcoursEntrepreneursConclusion,
  QuizEntrepreneuriat1,
  QuizEntrepreneuriat2,
  QuizEntrepreneuriat3,
  QuizEntrepreneuriat4,
  QuizEntrepreneuriat5,
  TrierCreationEntreprise,
} from 'components/activites/terminale/Entrepreneuriat';
import {
  QuestionsEntretien,
  ReponsesEntretien,
  SimulationEntretien,
  ConseilsEntretien,
} from 'components/activites/terminale/ArgumenterEntretien';
import {
  AnalyseOffrePro,
  InfographieDecrypterOffre,
  TonAnalyseOffreEmploi,
} from 'components/activites/terminale/Decrypter';
import {
  JePosteOuPasQuiz1,
  JePosteOuPasIntro,
  JePosteOuPasQuiz2,
  JePosteOuPasQuiz3,
  JePosteOuPasQuiz4,
  JePosteOuPasQuiz5,
  InfographieReseauxSociaux,
  VideoTemoignageReseauxSociaux,
  InfographieConseilsPreserverSonImage,
} from 'components/activites/terminale/MaitriserIdentiteDigitale';
import {
  EncadrementPhotosStep,
  FormationsPostBacPasserelles,
  RepartitionDevoirsMaisonPhotosStep,
  RevisionsGroupePhotosStep,
  FormatClassePhotosStep,
  TemoignagesFormationsPostBac,
} from 'components/activites/terminale/FormationsPostBac';
import {
  BudgetAndrea,
  DepensesEtudiant,
  InfographieDemenagement,
  ParcoursEtudiants,
} from 'components/activites/terminale/VieEtudiante';
import {
  FormationMotiveApports,
  FormationMotiveAtouts,
  FormationMotiveCadre,
  FormationMotiveContexte,
  FormationMotiveDemarches,
  FormationMotiveInforgraphie,
  FormationMotiveIntro,
  FormationMotiveQualites,
  FormationMotiveSummary,
} from 'components/activites/terminale/ProjetFormationMotive';
import {
  CapacitesSociales,
  CapacitesPersonnelles,
  DefinitionCompetencesTransversales,
  ExperienceStage,
  ImportanceSoftSkillsRecruteurs,
  TemoignageSoftSkills,
  CapacitesMethodologiques,
  PlanAction,
  EvaluationSociale,
  EvaluationPersonnelle,
  EvaluationMethodologique,
} from 'components/activites/terminale/CompetencesTransversales';
import {
  EtapesParcoursupTerm1,
  EtapesParcoursupTerm2,
  DossierParcoursup1,
  DossierParcoursup2,
  DossierParcoursup3,
  DossierParcoursup4,
  DossierParcoursup5,
  PropositionsFormations1,
  PropositionsFormations2,
  PropositionsFormations3,
  ReponsesCandidats1,
  ReponsesCandidats2,
  ReponsesCandidatsInfographie,
  PasDePropositions1,
  PasDePropositions2,
  PasDePropositions3,
  PasDePropositions4,
  EtapesParcoursupTerm3,
  InfosParcoursup1,
  InfosParcoursup2,
  InfosParcoursup3,
  InfosParcoursup4,
  CasPratique1,
  CasPratique2,
  CasPratique3,
  CasPratique4,
  CasPratique5,
  CasPratique6,
} from 'components/activites/terminale/EtapesParcoursup';
import Cvdesignr from 'components/activites/Cvdesignr';
import { makeBilanActivite } from 'lib/activites';
import QuestionnaireIcon from '@inspire/ui/assets/undraw/questions.svg';
import {
  Etudes,
  Attitude,
  Debouches,
  Encouragement,
  Finances,
  IntroQuestionnaire,
  LieuDeVie,
  Continue,
  AvenirProfessionnel1,
  AvenirProfessionnel2,
  Qualites,
  PrevisionPostBac,
  MatieresTroncCommun,
  MatieresSpes,
} from 'components/activites/premiere/decouvrir-pistes-davenir/QuestionnairePremiere';
import AlgoProgress from 'components/activites/premiere/decouvrir-pistes-davenir/AlgoProgress';
import {
  DecouvertePisteConclusion,
  DecouvertePisteConsignes,
  PisteRecoForm,
} from 'components/activites/premiere/decouvrir-pistes-davenir/decouverte-pistes';

// const hasFinishedQuestionnaire = {
//   condition: ({ myBacProActivites }) =>
//     myBacProActivites?.find(
//       (a) =>
//         a.activiteId === 'mon-questionnaire-terminale' &&
//         a.status === 'completed'
//     ),
//   reason:
//     'Tu dois compléter l\'activité "Mon questionnaire" avant de débuter cette activité.',
//   profReason:
//     'Vos élèves doivent compléter le module "Découvrir des pistes d’avenir" pour débloquer ce module.',
// };

export const QUESTIONNAIRE_TERMINALE = 'questionnaire-terminale';
const EVALUER_SOFT_SKILLS = 'evaluer-ses-soft-skills';
export const VALORISER_SES_COMPETENCES = 'valoriser-ses-competences';
export const DECRYPTER_UNE_OFFRE = 'decrypter-une-offre';
const ARGUMENTER_EN_ENTRETIEN = 'argumenter-en-entretien';
const MAITRISER_SON_INDENTITE_NUMERIQUE = 'maitriser-son-identite-numerique';
const ENTREPRENEURIAT = 'se-lancer-dans-lentrepreneuriat';
const VIE_ETUDIANTE = 'se-lancer-dans-la-vie-etudiante';
const FORMATIONS_POST_BAC = 'les-formations-post-bac';
const PARCOURSUP = 'parcoursup';
const PROJET_FORMATION_MOTIVE = 'projet-formation-motive';
const CVDESIGNR_TERMINALE = 'cvdesignr-terminale';
const ENQUETER_SUR_SON_SECTEUR = 'enqueter-sur-son-secteur';
const ENQUETER_SUR_SA_FORMATION = 'enqueter-sur-sa-formation';
export const DECOUVRIR_PISTES_AVENIR_ID = 'decouvrir-pistes-davenir';

const modules = [
  {
    theme: 'etudes-sup',
    moduleId: DECOUVRIR_PISTES_AVENIR_ID,
    title: 'Découvrir des pistes d’avenir',
    icon: QuestionnaireIcon,
    intro:
      "Après avoir répondu au questionnaire, tu découvriras des pistes d'avenir potentielles adaptées à ton parcours personnel.",
    profDescription:
      'Les élèves remplissent un questionnaire qui leur permettra d’avoir des pistes d’avenir personnalisées afin de leur montrer les possibilités qui s’offrent à eux par rapport à leur profil que soit la poursuite d’études après le bac ou l’insertion professionnelle. Vous aurez accès aux réponses fournies par vos élèves.',
    objectifPedagogique:
      'En ayant des pistes d’avenir proposées en adéquation avec les réponses au questionnaire, les élèves pourront prioriser leurs prises d’informations sur les pistes les plus susceptibles de leur correspondre.',
    isMandatory: true,
    activites: [
      {
        title: 'Mon questionnaire',
        activiteId: 'mon-questionnaire',
        duration: 20,
        profDescription:
          'L’activité a pour but de fournir aux lycéens, une fois le questionnaire rempli, une liste de pistes potentielles vers lesquelles ces derniers peuvent s’orienter. Ces pistes se déclinent sous forme de contenus informatifs traitant d’études supérieures ou d’insertion professionnelle.',
        description:
          "Une fois le questionnaire complété, tu découvriras les pistes d'avenir potentielles qui te seront proposées en fonction de tes réponses.",
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          { title: 'On va commencer !', component: IntroQuestionnaire },
          {
            title: 'Poursuite des études',
            component: PrevisionPostBac,
            hasOwnFooter: true,
          },
          {
            title: 'Lieu de vie et trajet',
            component: LieuDeVie,
            hasOwnFooter: true,
          },
          {
            title: 'Situation financière',
            component: Finances,
            hasOwnFooter: true,
          },
          { title: 'Débouchés', component: Debouches, hasOwnFooter: true },
          { title: 'On continue !', component: Continue, hasOwnFooter: true },
          { title: 'Tout va bien ?', component: Encouragement },
          {
            title: 'Avenir professionnel 1/2',
            component: AvenirProfessionnel1,
            hasOwnFooter: true,
          },
          {
            title: 'Avenir professionnel 2/2',
            component: AvenirProfessionnel2,
            hasOwnFooter: true,
          },
          { title: 'Qualités', component: Qualites, hasOwnFooter: true },
          {
            title: 'Matières',
            component: MatieresTroncCommun,
            hasOwnFooter: true,
          },
          { title: 'Matières', component: MatieresSpes, hasOwnFooter: true },
          { title: 'Études', component: Etudes, hasOwnFooter: true },
          {
            title: 'Attitude en classe',
            component: Attitude,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: 'Mes pistes recommandées',
        activiteId: 'mes-pistes-recommandees',
        duration: 20,
        description:
          'Cette activité te permet de découvrir des pistes d’avenir qui te sont proposées à la suite de tes réponses au questionnaire d’aide à l’orientation.',
        profDescription:
          'Cette activité permet aux lycéens de s’informer sur les pistes d’avenir qui leur ont été recommandées après avoir rempli leur questionnaire d’orientation.',
        objectifPedagogique:
          'L’objectif de ce module est de présenter quelques pistes d’avenir, de réfléchir autour de ces propositions et voir si celles-ci sont conformes ou pas à ce que les lycéens envisagent actuellement.',
        // requirements: [hasFinishedQuestionnaire],
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: 'Découverte des pistes',
            component: AlgoProgress,
            hasOwnFooter: true,
          },
          {
            title: 'Découverte des pistes',
            component: DecouvertePisteConsignes,
            hasOwnFooter: true,
          },
          {
            title: 'Découverte des pistes',
            component: PisteRecoForm,
            hasOwnFooter: true,
          },
          {
            title: 'Découverte des pistes',
            component: DecouvertePisteConclusion,
          },
        ],
      },
      makeBilanActivite(DECOUVRIR_PISTES_AVENIR_ID),
    ],
    bilanQuestions: [
      {
        id: 'citer-une-piste-avenir',
        text: "Je peux citer une piste d'avenir qui m'intéresse.",
      },
      {
        id: 'citer-avantages-inconvenients-piste',
        text: 'Je peux citer les avantages et inconvénients de cette piste.',
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: VIE_ETUDIANTE,
    title: 'Se lancer dans la vie étudiante',
    icon: GraduationIcon,
    intro:
      'Ce module te permettra de découvrir différents aspects de la vie étudiante comme les dépenses, les aides ou encore la possibilité de déménager.',
    profDescription:
      'Ce module permet aux lycéens d’anticiper différents aspects de la vie étudiante comme les dépenses, les aides, la mobilité.',
    objectifPedagogique:
      'Se préparer à la vie étudiante (budget, mobilité, aides étudiantes). Anticiper son budget. Connaître les aides et savoir où déposer ses dossiers. Envisager la mobilité.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je gère mon budget étudiant',
        activiteId: 'je-gere-mon-budget-etudiant',
        duration: 20,
        profDescription:
          'L’activité a pour but de faire comprendre aux élèves les éléments qui composent un budget étudiant.',
        description:
          'L’activité a pour but de t’apprendre à compléter un budget étudiant.',
        contentType: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: 'Récapitulatif des dépenses et des aides d’un étudiant.',
            component: DepensesEtudiant,
          },
          { title: 'Le budget étudiant d’Andréa', component: BudgetAndrea },
        ],
      },
      {
        title: 'Je déménage pour mes études',
        activiteId: 'je-demenage-pour-mes-etudes',
        duration: 20,
        profDescription:
          'L’activité a pour but de permettre aux élèves d’anticiper un déménagement éventuel pour leurs études.',
        description:
          'L’activité a pour but de te faire réfléchir à la possibilité de déménager dans le cadre des études supérieures.',
        contentType: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_FORM },
        ],

        steps: [
          {
            title: 'Parcours d’étudiants ayant déménagé pour leurs études',
            component: ParcoursEtudiants,
          },
          {
            title: 'Infographie : Organiser son déménagement en 8 étapes',
            component: InfographieDemenagement,
            hasOwnFooter: true,
          },
        ],
      },
      makeBilanActivite(VIE_ETUDIANTE),
    ],
    bilanQuestions: [
      {
        id: 'connait-depenses-etudiant',
        text: "J'ai une idée des dépenses que j'aurai en tant qu'étudiant.",
      },
      {
        id: 'connait-aides-etudiant',
        text: "J'ai une idée des aides que je peux avoir en tant qu'étudiant.",
      },
      {
        id: 'connait-sources-revenus-etudiant',
        text: "J'ai une idée des sources de revenus que je peux avoir en tant qu'étudiant.",
      },
      {
        id: 'connait-etapes-importantes-demenagement',
        text: "J'ai une idée des étapes importantes pour préparer un déménagement et l'arrivée dans une nouvelle ville.",
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: FORMATIONS_POST_BAC,
    title: 'Les formations post-bac : comment ça marche ?',
    icon: EducatorIcon,
    intro:
      'Ce module te permettra de mieux comprendre comment fonctionnent les formations post-bac.',
    profDescription:
      'Ce module a pour but de permettre aux lycéens de mieux appréhender le fonctionnement des études supérieures.',
    objectifPedagogique:
      "Connaître les possibilités de formations post-bac. Approfondir ses connaissances sur le fonctionnement du supérieur (crédits ects, passerelles,...). Prévention du décrochage dans le sup' = rythme de travail / méthodologie de travail, devoir maison, autonomie...",
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Les formations post-bac et leur fonctionnement',
        activiteId: 'formations-post-bac-fonctionnement',
        duration: 20,
        profDescription:
          'L’activité a pour but d’avoir les informations clés sur les principales formations post-bac et d’illustrer les passerelles dans le supérieur à travers des témoignages d’étudiants.',
        description:
          'A travers cette activité, tu auras des informations clés sur les principales formations post-bac.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: 'Témoignages sur les formations post-bac',
            component: TemoignagesFormationsPostBac,
          },
          {
            title: 'Les formations post-bac et les passerelles',
            component: FormationsPostBacPasserelles,
          },
        ],
      },
      {
        title: 'Le rythme dans les études sup’',
        activiteId: 'rythme-etudes-sup',
        duration: 15,
        profDescription:
          'L’activité a pour but d’aider les élèves à se projeter sur le changement de rythme dans les études supérieures.',
        description:
          'Cette activité a pour but de t’aider à anticiper le changement de rythme scolaire dans les études supérieures.',
        contentInfo: [],
        steps: [
          {
            title: 'Format des classes',
            component: FormatClassePhotosStep,
            hasOwnFooter: true,
          },
          {
            title: 'Encadrement',
            component: EncadrementPhotosStep,
            hasOwnFooter: true,
          },
          {
            title: 'Révisions en groupe',
            component: RevisionsGroupePhotosStep,
            hasOwnFooter: true,
          },
          {
            title: 'Répartition des devoirs maison',
            component: RepartitionDevoirsMaisonPhotosStep,
            hasOwnFooter: true,
          },
        ],
      },
      makeBilanActivite(FORMATIONS_POST_BAC),
    ],
    bilanQuestions: [
      {
        id: 'principaux-types-formation-post-bac',
        text: 'Je peux citer les principaux types de formation post-bac.',
      },
      {
        id: 'definir-passerelle-superieur',
        text: "Je peux définir ce qu'est une passerelle dans le supérieur.",
      },
      {
        id: 'connait-type-encadrement-besoin-superieur',
        text: "J'ai une idée du type d'encadrement dont j'ai besoin dans le supérieur.",
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: PARCOURSUP,
    title: 'Parcoursup : comment ça marche ?',
    icon: LaptopIcon,
    // isUpcoming: true,
    intro:
      'Ce module te permettra de mieux comprendre comment fonctionne Parcoursup.',
    profDescription:
      'Ce module a pour but de permettre aux lycéens de mieux appréhender le fonctionnement de Parcoursup.',
    objectifPedagogique:
      'Connaître les étapes clés de Parcoursup, savoir compléter son dossier sur Parcoursup, savoir répondre aux propositions sur Parcoursup, connaître les voies de recours si aucune proposition.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Les grandes étapes de Parcoursup',
        activiteId: 'etapes-parcoursup',
        duration: 15,
        profDescription:
          'L’activité a pour but de connaître les dates clés et le fonctionnement de Parcoursup.',
        description:
          'Avec cette activité, tu connaîtras les dates clés de Parcoursup et son fonctionnement.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: 'Les grandes étapes de Parcoursup en terminale',
            component: EtapesParcoursupTerm1,
          },
          {
            title: 'Les grandes étapes de Parcoursup en terminale',
            component: EtapesParcoursupTerm2,
          },
          {
            title: 'Les grandes étapes de Parcoursup en terminale',
            component: EtapesParcoursupTerm3,
          },
        ],
      },
      {
        title: 'Je complète mon dossier sur Parcoursup',
        activiteId: 'dossier-parcoursup',
        duration: 15,
        profDescription:
          'L’activité a pour but d’apprendre à compléter son dossier sur Parcoursup.',
        description:
          'L’activité a pour but de t’apprendre comment compléter ton dossier sur Parcoursup',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: '',
            component: DossierParcoursup1,
          },
          {
            title: '',
            component: DossierParcoursup2,
          },
          {
            title: '',
            component: DossierParcoursup3,
          },
          {
            title: '',
            component: DossierParcoursup4,
          },
          {
            title: '',
            component: DossierParcoursup5,
          },
        ],
      },
      {
        title: 'Je réponds aux propositions sur Parcoursup',
        activiteId: 'propositions-parcoursup',
        duration: 10,
        profDescription:
          'L’activité a pour but de connaître les différentes réponses délivrées par les formations et les options s’offrant aux candidats sur Parcoursup.',
        description:
          'Cette activité a pour but de t’aider à répondre aux propositions d’admission sur Parcoursup.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: 'Les propositions des formations',
            component: PropositionsFormations1,
          },
          {
            title: 'Les propositions des formations',
            component: PropositionsFormations2,
          },
          {
            title: 'Les propositions des formations',
            component: PropositionsFormations3,
          },
          {
            title: 'Les réponses des candidats aux propositions',
            component: ReponsesCandidatsInfographie,
          },
          {
            title: 'Les réponses des candidats aux propositions',
            component: ReponsesCandidats1,
          },
          {
            title: 'Les réponses des candidats aux propositions',
            component: ReponsesCandidats2,
          },
          {
            title: 'Si je n’ai pas eu de propositions d’admission ?',
            component: PasDePropositions1,
          },
          {
            title: 'Si je n’ai pas eu de propositions d’admission ?',
            component: PasDePropositions2,
          },
          {
            title: 'Si je n’ai pas eu de propositions d’admission ?',
            component: PasDePropositions3,
          },
          {
            title: 'Si je n’ai pas eu de propositions d’admission ?',
            component: PasDePropositions4,
          },
        ],
      },
      {
        title: 'Cas pratique : Comment répondre sur Parcoursup',
        activiteId: 'cas-pratique-repondre-parcoursup',
        duration: 20,
        profDescription:
          'L’activité a pour but de permettre aux élèves de mieux comprendre comment répondre aux propositions des formations sur Parcoursup à travers un cas pratique.',
        description:
          'Cette activité a pour but de t’aider à répondre aux propositions d’admission sur Parcoursup à travers un cas pratique.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: '',
            component: InfosParcoursup1,
          },
          {
            title: '',
            component: InfosParcoursup2,
          },
          {
            title: '',
            component: InfosParcoursup3,
          },
          {
            title: '',
            component: InfosParcoursup4,
          },
          {
            title: 'Cas pratique',
            component: CasPratique1,
          },
          {
            title: '2 juin',
            component: CasPratique2,
          },
          {
            title: '2 juin',
            component: CasPratique3,
          },
          {
            title: '6 juin',
            component: CasPratique4,
          },
          {
            title: '6 juin',
            component: CasPratique5,
          },
          {
            title: '29 juin',
            component: CasPratique6,
          },
        ],
      },
      makeBilanActivite(PARCOURSUP),
    ],
    bilanQuestions: [
      {
        id: 'connait-dates-parcoursup',
        text: 'Je connais les dates clés des étapes de Parcoursup.',
      },
      {
        id: 'sait-completer-dossier-parcoursup',
        text: 'Je sais comment compléter mon dossier sur Parcoursup.',
      },
      {
        id: 'sait-repondre-propositions-admission-parcoursup',
        text: "Je sais comment répondre aux propositions d'admission sur Parcoursup.",
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: PROJET_FORMATION_MOTIVE,
    title: 'Rédiger un projet de formation motivé sur Parcoursup',
    icon: TypewriterIcon,
    intro: 'Ce module t’aidera à rédiger un projet de formation motivé.',
    profDescription:
      'Ce module à pour objectif d’accompagner l’élève dans la rédaction de ses projets de formation motivés.',
    objectifPedagogique:
      'Connaître les éléments clés qui constituent un projet de formation motivé',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je rédige mon projet de formation motivé',
        activiteId: 'redaction-projet-formation-motive',
        duration: 40,
        profDescription:
          'L’activité a pour but d’accompagner l’élève dans la rédaction de ses projets de formation motivés.',
        description:
          'Cette activité a pour but de t’aider à rédiger un projet de formation motivé.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: 'Ma motivation pour la formation',
            component: FormationMotiveIntro,
          },
          {
            title: 'Contexte de la formation',
            component: FormationMotiveContexte,
            hasOwnFooter: true,
          },
          {
            title: 'Démarches réalisées',
            component: FormationMotiveDemarches,
            hasOwnFooter: true,
          },
          {
            title: 'Apports de la formation',
            component: FormationMotiveApports,
            hasOwnFooter: true,
          },
          {
            title: 'Cadre de la formation',
            component: FormationMotiveCadre,
            hasOwnFooter: true,
          },
          {
            title: 'Atouts',
            component: FormationMotiveAtouts,
            hasOwnFooter: true,
          },
          {
            title: 'Qualités et expériences',
            component: FormationMotiveQualites,
            hasOwnFooter: true,
          },
          { title: 'Résumé', component: FormationMotiveSummary },
          {
            title: 'Conseils',
            component: FormationMotiveInforgraphie,
          },
        ],
      },
      makeBilanActivite(PROJET_FORMATION_MOTIVE),
    ],
    bilanQuestions: [
      {
        id: 'connait-structure-projet-formation-motive',
        text: 'Je sais comment se structure un projet de formation motivé.',
      },
      {
        id: 'connait-informations-cle-projet-formation-motive',
        text: 'Je connais les informations clés à mettre dans mes projets de formation motivés.',
      },
    ],
  },
  {
    theme: 'etudes-sup',
    moduleId: ENQUETER_SUR_SA_FORMATION,
    title: 'Enquêter sur sa formation post-bac',
    icon: GoalsIcon,
    intro: 'TODO',
    isUpcoming: true,
    profDescription: 'TODO',
    objectifPedagogique: 'TODO',
    // requirements: [hasFinishedQuestionnaire],
    activites: [makeBilanActivite(ENQUETER_SUR_SA_FORMATION)],
    bilanQuestions: [
      {
        id: 'citer-formation-interesse',
        text: "Je peux citer une formation qui m'intéresse.",
      },
      {
        id: 'lister-informations-formation',
        text: 'Je peux lister les informations clés à connaître pour pouvoir me projeter dans une formation.',
      },
      {
        id: 'ressources-informations-formation',
        text: "J'ai des ressources pour trouver ces informations.",
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: EVALUER_SOFT_SKILLS,
    title: 'Évaluer ses compétences transversales',
    icon: PercentagesIcon,
    intro:
      "L'objectif de ce module est de te permettre d’identifier et d’évaluer tes compétences transversales/soft skills.",
    profDescription:
      'Ce module a pour objectif d’aider les lycéens à identifier et évaluer leurs compétences transversales/soft skills et vérifier leur marge de progression.',
    objectifPedagogique:
      'Savoir définir les compétences transversales/soft skills, savoir identifier et évaluer ses compétences transversales/soft skills.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je découvre les compétences transversales',
        activiteId: 'decouvrir-les-competences-transversales',
        duration: 25,
        profDescription:
          'L’activité a pour but de permettre aux lycéens de comprendre la notion de compétences transversales/soft skills.',
        description:
          'Cette activité te présente la notion de compétences transversales/soft skills.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
        ],
        steps: [
          {
            title: 'Qu’est-ce que les compétences transversales ?',
            component: DefinitionCompetencesTransversales,
          },
          {
            title:
              'L’importance des compétences transversales/soft skills pour les recruteurs',
            component: ImportanceSoftSkillsRecruteurs,
          },
          {
            title: 'Témoignage sur les compétences transversales',
            component: TemoignageSoftSkills,
          },
        ],
      },
      {
        title: 'J’identifie et j’évalue mes compétences transversales',
        activiteId: 'identifier-evaluer-competences-transversales',
        duration: 10,
        profDescription:
          'L’activité a pour but de permettre aux lycéens d’identifier leurs compétences transversales/soft skills.',
        description:
          'Cette activité a pour but de te permettre d’identifier tes compétences transversales/soft skills.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: 'Expérience de stage et tes tâches réalisées',
            component: ExperienceStage,
            hasOwnFooter: true,
          },
          {
            title: 'Capacités sociales',
            component: CapacitesSociales,
            hasOwnFooter: true,
          },
          {
            title: 'Capacités personnelles',
            component: CapacitesPersonnelles,
            hasOwnFooter: true,
          },
          {
            title: 'Capacités méthodologiques',
            component: CapacitesMethodologiques,
            hasOwnFooter: true,
          },
          {
            title: 'Évalue-toi : Capacité sociale',
            component: EvaluationSociale,
            hasOwnFooter: true,
          },
          {
            title: 'Évalue-toi : Capacité personnelle',
            component: EvaluationPersonnelle,
            hasOwnFooter: true,
          },
          {
            title: 'Évalue-toi : Capacité méthodologique',
            component: EvaluationMethodologique,
            hasOwnFooter: true,
          },
          {
            title: "Mon plan d'action",
            component: PlanAction,
            hasOwnFooter: true,
          },
        ],
      },
      makeBilanActivite(EVALUER_SOFT_SKILLS),
    ],
    bilanQuestions: [
      {
        id: 'definir-soft-skills-terminale',
        text: 'Je peux définir les compétences transversales (soft skills).',
      },
      {
        id: 'citer-3-soft-skills',
        text: 'Je peux citer 3 de mes compétences transversales (soft skills).',
      },
      {
        id: 'niveau-maitrise-3-soft-skills',
        text: 'Je connais mon niveau de maitrise de ces 3 compétences transversales (soft skills).',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: VALORISER_SES_COMPETENCES,
    title: 'Valoriser ses compétences dans sa candidature',
    icon: DecorateIcon,
    intro:
      "L'objectif de ce module est de te permettre de mettre en valeur tes compétences dans ta lettre de motivation.",
    profDescription:
      ' Ce module a pour objectif d’aider les lycéens à mettre en valeur leurs compétences techniques et transversales dans leur lettre de motivation.',
    objectifPedagogique:
      'Identifier les compétences techniques et transversales développées en stage en lien avec une offre d’emploi. Valoriser ses compétences dans une lettre de motivation.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je valorise mes compétences dans ma lettre de motivation',
        activiteId: 'valoriser-mes-competences',
        duration: 40,
        profDescription:
          'L’activité a pour but de faire compléter aux élèves une lettre de motivation à trous avec leurs compétences acquises (techniques et transversales).',
        description:
          'Cette activité t’aidera à présenter tes compétences techniques (hard skills) et tes compétences transversales (soft skills) dans une lettre de motivation.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM }],
        steps: [
          {
            title: "Analyse de l'offre",
            component: AnalyseOffre,
            hasOwnFooter: true,
          },
          {
            title: "Qu'as-tu fais pendant tes stages en entreprise ?",
            component: StageEnEntreprise,
            hasOwnFooter: true,
          },
          {
            title: 'Quelles sont tes soft skills ?',
            component: SoftSkills,
            hasOwnFooter: true,
          },
          {
            title: 'La lettre de motivation à trou',
            component: LettreDeMotivATrous,
            hasOwnFooter: true,
          },
          {
            title: 'Lecture du résultat',
            component: LectureDuResultat,
          },
        ],
      },
      makeBilanActivite(VALORISER_SES_COMPETENCES),
    ],
    bilanQuestions: [
      {
        id: 'soft-skills-lettre-motivation',
        text: 'Je peux citer mes compétences transversales (soft skills) dans mes lettres de motivation.',
      },
      {
        id: 'hard-skills-lettre-motivation',
        text: 'Je peux citer mes compétences techniques (hard skills) dans mes lettres de motivation.',
      },
      {
        id: 'citer-experiences-lettre-motivation',
        text: 'Je peux citer mes expériences dans mes lettres de motivation.',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: DECRYPTER_UNE_OFFRE,
    title: 'Décrypter une offre',
    icon: DecryptIcon,
    intro:
      "L'objectif de ce module est de t'apprendre à analyser des offres d'emploi pour mieux y répondre.",
    profDescription:
      "Ce module a pour objectif d’aider les lycéens à comprendre et analyser une offre d'emploi.",
    objectifPedagogique:
      'Être en mesure de décrypter une offre d’emploi : faire ressortir les éléments clés.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: "J'analyse une offre d'emploi",
        activiteId: 'analyse-offre-demploi',
        duration: 35,
        profDescription:
          "L'activité a pour but de permettre aux élèves d'apprendre à décrypter une offre d'emploi et ainsi être à même d'y répondre de façon appropriée.",
        description:
          "Cette activité t'entraine à comprendre les attentes d'une offre d'emploi pour savoir y répondre.",
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
        ],
        steps: [
          {
            title: "Ton analyse de l'offre d'emploi",
            component: TonAnalyseOffreEmploi,
          },
          {
            title: "Analyse d'une offre d'emploi par un professionnel",
            component: AnalyseOffrePro,
          },
          {
            title: 'Infographie "Décrypter une offre d\'emploi"',
            component: InfographieDecrypterOffre,
          },
        ],
      },
      makeBilanActivite(DECRYPTER_UNE_OFFRE),
    ],
    bilanQuestions: [
      {
        id: 'citer-elements-importants-offre-emploi',
        text: "Je peux citer les éléments importants à regarder dans une offre d'emploi.",
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: ARGUMENTER_EN_ENTRETIEN,
    title: 'Argumenter en entretien',
    icon: ArgumentIcon,
    intro:
      "L'objectif de ce module est de te préparer aux entretiens d'embauche.",
    profDescription:
      'Ce module a pour objectif d’aider à préparer au mieux les entretiens d’embauche.',
    objectifPedagogique:
      'Ce module permet aux élèves de renforcer leur argumentaire durant un entretien d’embauche et de se préparer aux questions difficiles.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je prépare mon argumentaire d’entretien',
        activiteId: 'preparer-argumentaire',
        duration: 35,
        profDescription:
          "L'activité a pour but de confronter les élèves aux questions difficiles posées durant un entretien. Celles-ci seront d'abord posées par un professionnel RH qui donnera ensuite les conseils nécessaires pour y être préparé.",
        description:
          "Cette activité te préparera à savoir répondre aux questions difficiles qui peuvent être posées durant un entretien d'embauche.",
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_GAME },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        steps: [
          {
            title: 'Que répondrais-tu aux 10 “questions pièges” en entretien ?',
            component: QuestionsEntretien,
          },
          {
            title: 'Les réponses aux questions',
            component: ReponsesEntretien,
          },
          {
            title: "Simulation d'entretien",
            component: SimulationEntretien,
          },
          {
            title: "Conseils d'une professionnelle du recrutement",
            component: ConseilsEntretien,
          },
        ],
      },
      makeBilanActivite(ARGUMENTER_EN_ENTRETIEN),
    ],
    bilanQuestions: [
      {
        id: 'capable-repondre-questions-delicates-entretien',
        text: 'Je me sens capable de répondre aux questions délicates en entretien.',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: CVDESIGNR_TERMINALE,
    title: 'Rediger son CV et sa lettre de motivation avec CVDesignR',
    icon: PersonDocumentIcon,
    intro:
      'Ce module a pour objectif de te permettre de rédiger un CV et une lettre de motivation adaptés à l’offre et à l’entreprise à laquelle tu postules.',
    profDescription:
      'Ce module a pour objectif de permettre aux élèves de rédiger un CV et une lettre de motivation qui soient adaptés à l’offre et à l’entreprise à laquelle ils postulent.',
    objectifPedagogique:
      "Les élèves sont capables de rédiger un CV, une lettre de motivation et de les adapter à l'offre et à l'entreprise",
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je réalise mon CV et ma LM sur CVDesignR',
        activiteId: 'cvdesignr-terminale-activite',
        description:
          'Cette activité te permet de choisir un modèle de CV qui te plait, de la compléter et de la faire corriger par ton enseignant. Tu peux également compléter une lettre de motivation et la faire corriger.',
        profDescription:
          'L’activité a pour but de sélectionner un modèle de CV, puis de le rédiger à l’aide de la trame proposée et enfin de le faire corriger par l’enseignant. Les élèves pourront également compléter une trame de lettre de motivation et la faire corriger.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_FORM, count: 1 }],
        duration: 120,
        component: Cvdesignr,
      },
      makeBilanActivite(CVDESIGNR_TERMINALE),
    ],
    bilanQuestions: [
      {
        id: 'capable-rediger-cv-terminale',
        text: 'Je me sens capable de rédiger un CV.',
      },
      {
        id: 'capable-rediger-lm-terminale',
        text: 'Je me sens capable de rédiger une LM.',
      },
      {
        id: 'capable-adapter-cv-lm-entreprise-terminale',
        text: "Je me sens capable d'adapter mon CV et ma LM à l'offre et à l'entreprise.",
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: MAITRISER_SON_INDENTITE_NUMERIQUE,
    title: 'Maîtriser son identité numérique',
    icon: IdentityIcon,
    intro:
      'Ce module te permettra de comprendre comment soigner son image sur Internet lorsqu’on cherche un stage ou un emploi.',
    profDescription:
      'Ce module a pour objectif de prendre conscience de l’importance de soigner son identité digitale lorsqu’on entre dans le monde du travail, dans un contexte où les réseaux sociaux sont fortement utilisés dans les processus de recrutement.',
    objectifPedagogique:
      'Identifier ce qu’on peut ou ne pas pas poster sur Internet pour soigner son identité digitale. Comprendre l’importance de l’identité numérique dans le processus de recrutement.',
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: 'Je poste ou je ne poste pas ?',
        activiteId: 'poste-ou-ne-poste-pas',
        duration: 15,
        description:
          'Cette activité te permet de comprendre le rôle des publications sur les réseaux sociaux dans le monde professionnel.',
        profDescription:
          'L’activité a pour but de sensibiliser les élèves sur un bon usage de ses réseaux sociaux pour ne pas les pénaliser aux yeux des recruteurs.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_ORAL }],
        steps: [
          {
            title: 'Introduction',
            component: JePosteOuPasIntro,
          },
          {
            title: 'Mes photos sur les réseaux',
            component: JePosteOuPasQuiz1,
          },
          {
            title: 'Mes photos sur les réseaux',
            component: JePosteOuPasQuiz2,
          },
          {
            title: 'Mes photos sur les réseaux',
            component: JePosteOuPasQuiz3,
          },
          {
            title: 'Mes photos sur les réseaux',
            component: JePosteOuPasQuiz4,
          },
          {
            title: 'Mes photos sur les réseaux',
            component: JePosteOuPasQuiz5,
          },
        ],
      },
      {
        title: 'Le poids des réseaux sociaux dans le recrutement',
        activiteId: 'poides-des-reseaux-sociaux',
        profDescription:
          'L’activité a pour but de prendre conscience du poids des réseaux sociaux dans les recrutements.',
        description:
          'Cette activité te permet de comprendre le rôle des réseaux sociaux dans les recrutements.',
        duration: 15,
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
        ],
        steps: [
          {
            title: 'L’usage des réseaux sociaux dans les recrutements',
            component: InfographieReseauxSociaux,
          },
          {
            title: 'Vidéo témoignage',
            component: VideoTemoignageReseauxSociaux,
          },
          {
            title: 'Infographie conseils pour préserver son image sur Internet',
            component: InfographieConseilsPreserverSonImage,
          },
        ],
      },
      makeBilanActivite(MAITRISER_SON_INDENTITE_NUMERIQUE),
    ],
    bilanQuestions: [
      {
        id: 'sait-trier-photos-reseaux-sociaux',
        text: 'Je sais trier les photos à mettre ou ne pas mettre sur les réseaux pour soigner mon identité digitale.',
      },
      {
        id: 'sait-recruteurs-reseaux-sociaux',
        text: 'Je sais que les recruteurs utilisent les réseaux sociaux pour se renseigner sur les candidats.',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: ENTREPRENEURIAT,
    title: "Se lancer dans l'entrepreneuriat",
    icon: BusinessIcon,
    intro: "Ce module te permettra de mieux comprendre l'entrepreneuriat.",
    profDescription:
      "Ce module a pour but de faire découvrir l'univers de l'entrepreneuriat aux lycéens.",
    objectifPedagogique:
      "Permettre aux élèves d'avoir en tête les différentes étapes pour construire son projet d'entrepreneuriat.",
    // requirements: [hasFinishedQuestionnaire],
    activites: [
      {
        title: "Parcours d'entrepreneurs",
        activiteId: 'parcours-entrepreneurs',
        duration: 15,
        profDescription:
          "Via des témoignages, l'activité présente des parcours d'entrepreneurs afin de permettre aux élèves d'identifier les leviers et étapes de l'entrepreneuriat.",
        description:
          'Cette activité te permet de comprendre les différentes étapes pour créer son entreprise, ainsi que les éléments ayant motivés des entrepreneurs à se lancer.',
        contentInfo: [
          { type: ACTIVITE_CONTENT_TYPE_ORAL },
          { type: ACTIVITE_CONTENT_TYPE_VIDEO },
        ],
        steps: [
          {
            title: 'Vidéos',
            // TODO: upload videos and update urls
            component: ParcoursEntrepreneurs,
          },
          {
            title: 'Conclusion',
            component: ParcoursEntrepreneursConclusion,
            hasOwnFooter: true,
          },
        ],
      },
      {
        title: "Quiz sur l'entrepreneuriat",
        activiteId: 'quiz-entrepreneuriat',
        duration: 20,
        profDescription:
          'A travers ce quiz, les élèves testent leurs connaissances sur le monde de l’entrepreneuriat.',
        description:
          'Cette activité te permettra de tester tes connaissances sur l’entrepreneuriat.',
        contentInfo: [{ type: ACTIVITE_CONTENT_TYPE_GAME }],
        steps: [
          {
            title: "Les étapes de la création d'entreprise",
            component: TrierCreationEntreprise,
          },
          {
            title: 'Quiz entrepreneuriat',
            component: QuizEntrepreneuriat1,
          },
          {
            title: 'Quiz entrepreneuriat',
            component: QuizEntrepreneuriat2,
          },
          {
            title: 'Quiz entrepreneuriat',
            component: QuizEntrepreneuriat3,
          },
          {
            title: 'Quiz entrepreneuriat',
            component: QuizEntrepreneuriat4,
          },
          {
            title: 'Quiz entrepreneuriat',
            component: QuizEntrepreneuriat5,
          },
          {
            title: 'Les étapes pour créer son entreprise',
            component: EtapesEntreprise,
            hasOwnFooter: true,
          },
        ],
      },
      makeBilanActivite(ENTREPRENEURIAT),
    ],
    bilanQuestions: [
      {
        id: 'citer-etapes-creation-entreprise',
        text: 'Je peux citer les principales étapes pour construire son entreprise.',
      },
    ],
  },
  {
    theme: 'insertion-pro',
    moduleId: ENQUETER_SUR_SON_SECTEUR,
    title: 'Enquêter sur son secteur',
    icon: FileAnalysisIcon,
    intro: 'TODO',
    isUpcoming: true,
    profDescription: 'TODO',
    objectifPedagogique: 'TODO',
    // requirements: [hasFinishedQuestionnaire],
    activites: [makeBilanActivite(ENQUETER_SUR_SON_SECTEUR)],
    bilanQuestions: [
      {
        id: 'citer-secteur-metier-interesse',
        text: "Je peux citer un secteur ou un métier qui m'intéresse.",
      },
      {
        id: 'lister-infos-imaginer-secteur-metier',
        text: "Je peux lister les informations clés à connaître pour pouvoir m'imaginer dans un secteur / un métier.",
      },
      {
        id: 'ressources-trouver-infos-secteur-metier',
        text: "J'ai des ressources pour trouver ces informations.",
      },
    ],
  },
];

export default modules;
