import SimpleSchema from "@inspire/data/schemas/SimpleSchema";
import { Classes } from "@inspire/data/static/static";
import { BoostSchema } from "@inspire/data/schemas/user/profile/boost";
import { SoftSkillsOptions, SoftSkillsDifficultesOptions, SoftSkillsRessentiOptions } from "../../../static/soft-skills";
var FavoritePiste = new SimpleSchema({
    id: {
        type: String,
        label: "id"
    }
});
var FavoriteEclaireur = new SimpleSchema({
    _id: {
        type: String,
        label: "id"
    }
});
var ProchaineEtapeSchema = new SimpleSchema({
    name: {
        type: String
    },
    status: {
        type: String
    }
});
var StringAnswerSchema = new SimpleSchema({
    key: {
        type: String
    },
    value: {
        type: String
    }
});
var NumberAnswerSchema = new SimpleSchema({
    key: {
        type: String
    },
    value: {
        type: Number
    }
});
export var PisteOpinionSchema = new SimpleSchema({
    pisteId: {
        type: String
    },
    pisteName: {
        type: String
    },
    like: {
        type: String,
        optional: true
    },
    dislike: {
        type: String,
        optional: true
    }
});
export var BilanModuleQuestionSchema = new SimpleSchema({
    id: {
        type: String
    },
    answer: {
        type: Number,
        // This would typically be a 5-level scale, but we are using a 3-level scale.
        // In order to be future-proof in case we switch to a 5-level scale, the allowed
        // values are -2, 0, and 2, so that we can add -1 and 1 later without migration.
        allowedValues: [
            -2,
            0,
            2
        ]
    }
});
export var BilanModuleSchema = new SimpleSchema({
    moduleId: {
        type: String
    },
    questions: {
        type: Array
    },
    "questions.$": {
        type: BilanModuleQuestionSchema
    }
});
export var CentresDinteretsSchema = new SimpleSchema({
    centresDinterets: {
        type: Array
    },
    "centresDinterets.$": {
        type: String
    },
    sport: {
        type: String,
        optional: true
    },
    danse: {
        type: String,
        optional: true
    },
    autre: {
        type: String,
        optional: true
    }
});
export var MetierOuSecteurReveSchema = new SimpleSchema({
    saitMetierOuSecteur: {
        type: Boolean
    },
    metierOuSecteur: {
        type: String,
        optional: true
    },
    pourraitInteresser: {
        type: Array,
        optional: true
    },
    "pourraitInteresser.$": {
        type: String
    }
});
export var choixFormationSchema = new SimpleSchema({
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire" Question "As-tu choisi ta formation actuelle"
   */ choisiFormation: {
        type: String,
        allowedValues: [
            "none",
            "yes",
            "no"
        ]
    },
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire" Question "Pourquoi as-tu choisi cette formation ?"
   */ pourquoiChoisi: {
        type: String,
        optional: true
    },
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire" Question "Et finalement ça t'intéresse ?"
   */ interessant: {
        type: String,
        optional: true,
        allowedValues: [
            "none",
            "yes",
            "partly",
            "notReally"
        ]
    }
});
export var adequationPreferencesApprentissageSchema = new SimpleSchema({
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire" Question "Est-ce que ta formation actuelle correspond à tes préférences d'apprentissage"
   */ correspond: {
        type: String,
        allowedValues: [
            "none",
            "yes",
            "partly",
            "no"
        ]
    },
    pourquoiNon: {
        type: String,
        optional: true
    }
});
export var pointsFortsScolairesSchema = new SimpleSchema({
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire" Question "Quelles sont les trois matières dans lesquelles tu as obtenu les meilleurs résultats au cours du 1er trimestre ? "
   */ meilleuresMatieres: {
        type: Array
    },
    "meilleuresMatieres.$": {
        type: String
    },
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire" Question "Ces matières correspondent-elles à tes matières préférées ?"
   */ correspondentAuxMatieresPreferees: {
        type: String,
        allowedValues: [
            "none",
            "yes",
            "partly",
            "notReally"
        ]
    }
});
export var LyceenBacProProfileSchema = new SimpleSchema({
    diplomes: {
        type: Array,
        optional: true
    },
    "diplomes.$": {
        type: String,
        optional: false,
        max: 30
    },
    ressentiFormation: {
        type: Number,
        optional: true
    },
    difficulteTrouverStage: {
        type: Number,
        optional: true
    },
    attitudes: {
        type: Array,
        optional: true
    },
    "attitudes.$": {
        type: NumberAnswerSchema,
        optional: false
    },
    previsionOccupationPostBac: {
        type: String,
        allowedValues: [
            "etudes",
            "travail",
            "ne-sait-pas"
        ],
        optional: true
    },
    previsionAnneesEtudesPostBac: {
        type: Number,
        min: 1,
        max: 5,
        optional: true
    },
    previsionQuitterDomicileParental: {
        type: Boolean,
        optional: true
    },
    previsionEloignementDomicileParental: {
        type: String,
        allowedValues: [
            "departement",
            "region",
            "france",
            "etranger"
        ],
        optional: true
    },
    previsionTempsMaxTrajetParJour: {
        type: String,
        allowedValues: [
            "30m",
            "1h",
            "2h",
            "2h+"
        ],
        optional: true
    },
    previsionTypeTransport: {
        type: Array,
        optional: true,
        minCount: 1
    },
    "previsionTypeTransport.$": {
        type: String,
        allowedValues: [
            "pied",
            "transports",
            "voiture",
            "covoiturage",
            "moto",
            "velo", 
        ]
    },
    connaitCoutEtudes: {
        type: Number,
        optional: true,
        min: 1,
        max: 7
    },
    financementEtudesFrein: {
        type: Number,
        optional: true,
        min: 1,
        max: 7
    },
    connaitPersonnesMemeDomaine: {
        type: Number,
        optional: true,
        min: 0,
        max: 5
    },
    prevoitCreerEntreprise: {
        type: Boolean,
        optional: true
    },
    mesQualites: {
        type: Array,
        optional: true,
        minCount: 3,
        maxCount: 3,
        label: "Choisis les 3 qualit\xe9s qui te ressemblent le plus :"
    },
    "mesQualites.$": {
        type: String,
        optional: true
    },
    qualitesAmeliorer: {
        type: Array,
        optional: true,
        minCount: 1,
        maxCount: 1,
        label: "Choisis la qualit\xe9 sur laquelle tu souhaites le plus t'am\xe9liorer"
    },
    "qualitesAmeliorer.$": {
        type: String,
        optional: true
    },
    howToImproveQualite: {
        type: String,
        optional: true,
        max: 200
    },
    etapesProjet: {
        type: Array,
        optional: true
    },
    "etapesProjet.$": {
        type: String,
        optional: true
    },
    softSkillsExperience: {
        type: String,
        optional: true
    },
    softSkillsList: {
        type: Array,
        optional: true,
        maxCount: 3
    },
    "softSkillsList.$": {
        type: String,
        allowedValues: Object.keys(SoftSkillsOptions)
    },
    softSkillsDifficultes: {
        type: Array,
        optional: true,
        maxCount: 2
    },
    "softSkillsDifficultes.$": {
        type: String,
        allowedValues: Object.keys(SoftSkillsDifficultesOptions)
    },
    softSkillsActions: {
        type: String,
        optional: true
    },
    softSkillsRessenti: {
        type: Array,
        optional: true,
        maxCount: 3
    },
    "softSkillsRessenti.$": {
        type: String,
        allowedValues: Object.keys(SoftSkillsRessentiOptions)
    },
    softSkillsAmeliorations: {
        type: String,
        optional: true
    },
    competencesActivite: {
        type: String,
        optional: true
    },
    competencesList: {
        type: Array,
        optional: true,
        maxCount: 3,
        minCount: 3
    },
    "competencesList.$": {
        type: String,
        allowedValues: Object.keys(SoftSkillsOptions)
    },
    planActionObjectif: {
        type: String,
        optional: true
    },
    planActionTemps: {
        type: String,
        optional: true
    },
    planActionEntreprendre: {
        type: String,
        optional: true
    },
    planActionComplementaire: {
        type: String,
        optional: true
    },
    stageDuree: {
        type: String,
        optional: true
    },
    stageSecteurActivite: {
        type: String,
        optional: true
    },
    stageMetier: {
        type: String,
        optional: true
    },
    stageGeographie: {
        type: String,
        optional: true
    },
    stageMissions: {
        type: String,
        optional: true
    },
    stageTypeEntreprise: {
        type: String,
        optional: true
    },
    stageMotsCles: {
        type: String,
        optional: true
    },
    stageSite: {
        type: String,
        optional: true
    },
    checklistCV: {
        type: Array,
        optional: true
    },
    "checklistCV.$": {
        type: String,
        optional: true
    },
    checklistLM: {
        type: Array,
        optional: true
    },
    "checklistLM.$": {
        type: String,
        optional: true
    },
    pistesOpinions: {
        type: Array,
        optional: true
    },
    "pistesOpinions.$": {
        type: PisteOpinionSchema
    },
    entretienFeedback: {
        type: Array,
        optional: true
    },
    "entretienFeedback.$": {
        type: StringAnswerSchema
    },
    softSkillEvaluations: {
        type: Array,
        optional: true
    },
    "softSkillEvaluations.$": {
        type: StringAnswerSchema
    },
    debouches: {
        type: Array,
        optional: true
    },
    "debouches.$": {
        type: NumberAnswerSchema
    },
    avenirPro: {
        type: Array,
        optional: true
    },
    "avenirPro.$": {
        type: NumberAnswerSchema
    },
    bilansModules: {
        type: Array,
        optional: true
    },
    "bilansModules.$": {
        type: BilanModuleSchema
    },
    analyseOffreEmploi: {
        type: Array,
        optional: true
    },
    "analyseOffreEmploi.$": {
        type: String,
        optional: true
    },
    stageEntrepriseSoftSkills: {
        type: String,
        optional: true
    },
    stageEntrepriseActivite: {
        type: String,
        optional: true
    },
    lettreDeMotiv: {
        type: Array,
        optional: true
    },
    "lettreDeMotiv.$": {
        type: StringAnswerSchema,
        optional: true
    },
    etapesEntreprise: {
        type: Array,
        optional: true
    },
    "etapesEntreprise.$": {
        type: NumberAnswerSchema,
        optional: true
    },
    creationEntrepriseOpinion: {
        type: Array,
        optional: true
    },
    "creationEntrepriseOpinion.$": {
        type: String,
        optional: true
    },
    demenagement: {
        type: String,
        optional: true
    },
    typeLogement: {
        type: Array,
        optional: true
    },
    "typeLogement.$": {
        type: String,
        optional: true
    },
    gererBudget: {
        type: String,
        optional: true
    },
    rencontrerDuMonde: {
        type: Array,
        optional: true
    },
    "rencontrerDuMonde.$": {
        type: String,
        optional: true
    },
    preferenceFormatClasse: {
        type: String,
        optional: true,
        allowedValues: [
            "amphi",
            "classe"
        ]
    },
    preferenceEncadrement: {
        type: String,
        optional: true,
        allowedValues: [
            "encadre",
            "autonome"
        ]
    },
    preferenceRevisions: {
        type: String,
        optional: true,
        allowedValues: [
            "groupe",
            "seul"
        ]
    },
    preferenceEmploiDuTemps: {
        type: String,
        optional: true,
        allowedValues: [
            "beaucoup-cours-peu-devoirs",
            "beaucoup-devoirs-peu-cours"
        ]
    },
    formationMotiveContexte: {
        type: String,
        optional: true
    },
    formationMotiveDemarches: {
        type: String,
        optional: true
    },
    formationMotiveApports: {
        type: String,
        optional: true
    },
    formationMotiveCadre: {
        type: String,
        optional: true
    },
    formationMotiveAtouts: {
        type: String,
        optional: true
    },
    formationMotiveQualites: {
        type: String,
        optional: true
    },
    experienceStage: {
        type: String,
        optional: true
    },
    stageCapacitesSociales: {
        type: String,
        optional: true
    },
    stageCapacitesPersonnelles: {
        type: String,
        optional: true
    },
    stageCapacitesMethodologiques: {
        type: String,
        optional: true
    },
    softSkillsPlanAction: {
        type: String,
        optional: true
    },
    formationRoute: {
        type: String,
        optional: true,
        allowedValues: [
            "none",
            "carrefour",
            "autoroute",
            "labyrinthe"
        ]
    },
    centresDinterets: {
        type: CentresDinteretsSchema,
        optional: true
    },
    preferencesApprentissage: {
        type: Array,
        optional: true
    },
    "preferencesApprentissage.$": {
        type: String
    },
    prioritesAvenir: {
        type: Array,
        optional: true
    },
    "prioritesAvenir.$": {
        type: String
    },
    matieresPreferes: {
        type: Array,
        optional: true
    },
    "matieresPreferes.$": {
        type: String
    },
    meConnaitreQualites: {
        type: Array,
        optional: true
    },
    "meConnaitreQualites.$": {
        type: String
    },
    metierOuSecteurReve: {
        type: MetierOuSecteurReveSchema,
        optional: true
    },
    /**
   * Réponses Module Seconde Activité "Mon Parcours Scolaire"
   */ choixFormation: {
        type: choixFormationSchema,
        optional: true
    },
    /**
   * Réponses Module Seconde Activité "Mon Parcours Scolaire"
   */ adequationPreferencesApprentissage: {
        type: adequationPreferencesApprentissageSchema,
        optional: true
    },
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire - Adéquation avec mon métier ou secteur de rêve"
   */ adequationMetierOuSecteurReve: {
        type: String,
        allowedValues: [
            "none",
            "yes",
            "partly",
            "notReally",
            "unknown"
        ],
        optional: true
    },
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire - Adéquation avec mes centres d'intérets et priorités"
   */ adequationCentresInteretsPriorites: {
        type: String,
        allowedValues: [
            "none",
            "yes",
            "partly",
            "notReally"
        ],
        optional: true
    },
    /**
   * Réponse Module Seconde Activité "Mon Parcours Scolaire - Adéquation avec mes qualités"
   */ adequationQualites: {
        type: String,
        allowedValues: [
            "none",
            "yes",
            "partly",
            "notReally"
        ],
        optional: true
    },
    /**
   * Réponses Module Seconde Activité "Mon Parcours Scolaire - Mes points forts scolaires"
   */ pointsFortsScolaires: {
        type: pointsFortsScolairesSchema,
        optional: true
    }
});
var lyceeStudiesItem = new SimpleSchema({
    niveau: {
        type: String,
        allowedValues: Object.keys(Classes)
    },
    annee: {
        type: Number
    },
    filiere: {
        type: String,
        optional: true
    },
    filiereBacPro: {
        type: String,
        optional: true
    },
    filiereTechno: {
        type: String,
        optional: true
    }
});
var LyceenProfileSchema = new SimpleSchema({
    lyceeStudies: {
        type: Array,
        optional: true
    },
    "lyceeStudies.$": {
        type: lyceeStudiesItem,
        optional: true
    },
    // keep favorites in the separate simple schema so its
    // optional flag does not get overriden when setting up LyceenSignupFormSchema
    favoritePistes: {
        type: Array,
        optional: true,
        label: "Pistes favorites"
    },
    "favoritePistes.$": {
        type: FavoritePiste,
        optional: true
    },
    favoriteEclaireurs: {
        type: Array,
        optional: true,
        label: "Eclaireurs favori.te.s"
    },
    "favoriteEclaireurs.$": {
        type: FavoriteEclaireur,
        optional: true
    },
    // additional field to keep track of a first contact request
    hasMadeContactRequest: {
        type: Boolean,
        optional: true
    },
    moyenne: {
        type: Number,
        optional: true
    },
    // classe when user signed up
    signupClasse: {
        type: String,
        optional: true,
        allowedValues: Object.keys(Classes),
        label: "Classe \xe0 l'inscription"
    },
    // date when classe was last updated
    classeUpdatedAt: {
        type: Date,
        optional: true
    },
    // date when lyceen became an eclaireur
    passageEclaireurAt: {
        type: Date,
        optional: true
    },
    // year when user was moved to Post-bac
    anneeBac: {
        type: Number,
        optional: true,
        label: "Ann\xe9e du Bac"
    },
    inscriptionPedagogique: {
        type: Boolean,
        optional: true
    },
    prochainesEtapes: {
        type: Array,
        optional: true
    },
    "prochainesEtapes.$": {
        type: ProchaineEtapeSchema,
        optional: true
    }
}).extend(BoostSchema).extend(LyceenBacProProfileSchema);
export { LyceenProfileSchema };
